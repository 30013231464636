import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Typography, MenuItem, Box, Divider, Stack, Tooltip } from '@mui/material';
// hooks
import useAuth from '../../hooks/useAuth';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Iconify from '../../components/Iconify';
import MenuPopover from '../../components/MenuPopover';
import { IconButtonAnimate } from '../../components/animate';
import { ConditionalWrapper } from '../../utils/ConditionalWrapper';

// ----------------------------------------------------------------------

const ROUTE_OPTIONS = [
  {
    label: 'Account Settings',
    linkTo: PATH_DASHBOARD.settings.account,
  },
  {
    label: 'Organisation Settings',
    linkTo: PATH_DASHBOARD.settings.organisation,
  },
  {
    label: 'Billing',
    linkTo: PATH_DASHBOARD.billing.root,
  },
  {
    label: 'Integrations',
    linkTo: PATH_DASHBOARD.integrations.root,
  },
];

// ----------------------------------------------------------------------

export default function SettingsPopover() {
  const { user } = useAuth();
  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
        sx={{
          width: 40,
          height: 40,
          ...(openPopover && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <Iconify icon="ic:round-settings" />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(openPopover)}
        anchorEl={openPopover}
        onClose={handleClosePopover}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            Settings
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {ROUTE_OPTIONS.map((option) => (
            <ConditionalWrapper
              key={option.label}
              condition={user.role !== 'team-admin' && (option.label === 'Billing' || option.label === 'Integrations')}
              wrapper={(children) => (
                <Tooltip title="No permission">
                  <Box>{children}</Box>
                </Tooltip>
              )}
            >
              <MenuItem
                to={option.linkTo}
                component={RouterLink}
                onClick={handleClosePopover}
                disabled={user.role !== 'team-admin' && (option.label === 'Billing' || option.label === 'Integrations')}
              >
                {option.label}
              </MenuItem>
            </ConditionalWrapper>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
