import PropTypes from 'prop-types';
// @mui
import { IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import Flag from './Flag';
import { getCallingCodeOfCountry } from '../../utils/telFieldHelpers';

// ----------------------------------------------------------------------

const TextStyle = styled(Typography)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.grey[300]}`,
  paddingRight: 10,
  cursor: 'default',
  pointerEvents: 'none',
}));

// ----------------------------------------------------------------------

const FlagButton = ({
  disableDropdown = false,
  forceCallingCode = false,
  isFlagsMenuOpened = false,
  isoCode,
  flagSize = 'small',
  ...iconButtonProps
}) => (
  <>
    {disableDropdown ? (
      <IconButton
        tabIndex={-1}
        className="MuiTelInput-IconButton"
        // eslint-disable-next-line jsx-a11y/aria-role
        role=""
        disableRipple
        // @ts-ignore
        sx={{ pointerEvents: 'none', aspectRatio: '1 / 1' }}
        component="span"
      >
        <Flag size={flagSize} isoCode={isoCode} />
      </IconButton>
    ) : (
      <IconButton
        {...iconButtonProps}
        aria-label="Select country"
        className="MuiTelInput-IconButton"
        aria-haspopup="listbox"
        sx={{ aspectRatio: '1 / 1' }}
        aria-controls={isFlagsMenuOpened ? 'select-country' : undefined}
        aria-expanded={isFlagsMenuOpened ? 'true' : 'false'}
      >
        <Flag size={flagSize} isoCode={isoCode} />
      </IconButton>
    )}
    {forceCallingCode && isoCode ? <TextStyle>+{getCallingCodeOfCountry(isoCode)}</TextStyle> : null}
  </>
);

FlagButton.propTypes = {
  disableDropdown: PropTypes.bool,
  forceCallingCode: PropTypes.bool,
  isFlagsMenuOpened: PropTypes.bool,
  flagSize: PropTypes.string,
  isoCode: PropTypes.string,
};

export default FlagButton;
