import { COUNTRIES, DEFAULT_ISO_CODE } from '../constants/countries';
import { CONTINENTS } from '../constants/continents';

const matchIsObject = (value) => typeof value === 'object' && !Array.isArray(value) && value !== null;

const getOnlyCountries = (countries, onlyCountries) => countries.filter((isoCode) => onlyCountries.includes(isoCode));

const sortedPreferredCountries = (countries, preferredCountries) => [...new Set(preferredCountries.concat(countries))];

const getCountriesOfContinents = (continents) => continents.flatMap((continentCode) => CONTINENTS[continentCode]);

const excludeCountries = (countries, excludedCountries) => {
  if (matchIsArray(excludedCountries, true)) {
    return countries.filter((isoCode) => !excludedCountries.includes(isoCode));
  }

  return countries;
};

export const getCallingCodeOfCountry = (isoCode) => COUNTRIES[isoCode]?.[0];

export const getValidCountry = (country) => country || DEFAULT_ISO_CODE;

export const matchContinentsIncludeCountry = (continents, isoCode) =>
  continents.some((continentCode) => CONTINENTS[continentCode].includes(isoCode));

export const matchIsArray = (value, filled) => {
  const isArray = Array.isArray(value);

  return filled ? isArray && value.length > 0 : isArray;
};

export const getFirstIntersection = (arrayA, arrayB) => arrayA.find((element) => arrayB.includes(element)) || null;

export const removeOccurrence = (text, part) => text.replace(part, '');

export function putCursorAtEndOfInput(inputElement) {
  inputElement.focus();
  const { length } = inputElement.value;
  inputElement.setSelectionRange(length, length);
}

export function assocRefToPropRef(ref, propRef) {
  if (typeof propRef === 'function') {
    propRef(ref);
  } else if (propRef && matchIsObject(propRef) && 'current' in propRef) {
    propRef.current = ref;
  }
}

export function getDisplayNames(lang = 'en') {
  try {
    return new Intl.DisplayNames(lang, {
      type: 'region',
    });
  } catch (error) {
    return new Intl.DisplayNames('en', {
      type: 'region',
    });
  }
}

export function sortAlphabeticallyCountryCodes(countryCodes, displayNames) {
  return [...countryCodes].sort((countryCodeA, countryCodeB) => {
    const countryA = displayNames.of(countryCodeA);
    const countryB = displayNames.of(countryCodeB);

    return countryA.localeCompare(countryB);
  });
}

export function filterCountries(countries, options) {
  const { onlyCountries, excludedCountries, continents, preferredCountries } = options;

  if (matchIsArray(onlyCountries, true)) {
    const filteredCountries = getOnlyCountries(countries, onlyCountries);

    return matchIsArray(preferredCountries, true)
      ? sortedPreferredCountries(filteredCountries, preferredCountries)
      : filteredCountries;
  }

  const theCountries = matchIsArray(continents, true) ? getCountriesOfContinents(continents) : countries;

  const sortedCountries = matchIsArray(preferredCountries, true)
    ? sortedPreferredCountries(theCountries, preferredCountries)
    : theCountries;

  return matchIsArray(excludedCountries, true) ? excludeCountries(sortedCountries, excludedCountries) : sortedCountries;
}
