import { useState } from 'react';
import PropTypes from 'prop-types';
import { TwitterPicker } from 'react-color';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Box, IconButton } from '@mui/material';
// components
import colourPickerList from '../../utils/colourPickerList';
import MenuPopover from '../MenuPopover';

// ----------------------------------------------------------------------

RHFColorPicker.propTypes = {
  name: PropTypes.string,
  containerSx: PropTypes.object,
  size: PropTypes.number,
};

export default function RHFColorPicker({ name, containerSx, size = 14 }) {
  const { control } = useFormContext();

  const [open, setOpen] = useState(null);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <>
          <Box sx={containerSx}>
            <IconButton
              onClick={(e) => {
                setOpen(e.currentTarget);
              }}
            >
              <Box
                sx={{
                  width: size,
                  height: size,
                  display: 'flex',
                  borderRadius: '50%',
                  bgcolor: field?.value || colourPickerList[0],
                }}
              />
            </IconButton>
          </Box>

          <MenuPopover
            open={Boolean(open)}
            onClose={() => setOpen(null)}
            anchorEl={open}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            disabledArrow
            sx={{ p: 1, width: 292 }}
          >
            <TwitterPicker
              triangle="hide"
              colors={colourPickerList}
              color={field?.value || '#ccc'}
              onChangeComplete={(color) => {
                field.onChange(color.hex);
              }}
            />
          </MenuPopover>
        </>
      )}
    />
  );
}
