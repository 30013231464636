import { createContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { onSnapshot, collection, query } from 'firebase/firestore';
// hooks
import useAuth from '../hooks/useAuth';
import { db } from '../utils/firebase';

const initialState = {
  donorIsInitialized: false,
  donors: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE':
      return {
        ...state,
        donors: action.payload.donors,
        donorIsInitialized: true,
      };
    default:
      return state;
  }
};

const DonorContext = createContext({
  ...initialState,
});

const DonorProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user } = useAuth();
  const [initialised, setInitialised] = useState(false);

  const { institution } = user || {};

  useEffect(() => {
    if (!initialised) {
      return null;
    }

    const unsubsciber = onSnapshot(query(collection(db, 'institutions', institution, 'donors')), (querySnapshot) => {
      const donors = [];
      querySnapshot.forEach((donor) => {
        donors.push({ ...donor.data(), id: donor.id });
      });

      console.log('Donor Context Called!');
      dispatch({
        type: 'INITIALISE',
        payload: { donors },
      });
    });

    return () => unsubsciber();
  }, [institution, initialised]);

  const commenceDonorInitialisation = () => {
    setInitialised(true);
  };

  return (
    <DonorContext.Provider
      value={{
        ...state,
        commenceDonorInitialisation,
      }}
    >
      {children}
    </DonorContext.Provider>
  );
};

DonorProvider.propTypes = {
  children: PropTypes.node,
};

export { DonorContext, DonorProvider };
