import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// paths
import { PATH_AUTH, PATH_PAGE } from '../routes/paths';
// pages
import Login from '../pages/auth/Login';
import VerifyEmail from '../pages/auth/VerifyEmail';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, authLoading, user } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  const { verified, onBoardingCompleted, role } = user || {};

  const isStaff = role === 'staff';

  if (authLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (!verified) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <VerifyEmail />;
  }

  if (isStaff) {
    return <Navigate to={PATH_PAGE.noAccess} />;
  }

  if (pathname !== PATH_AUTH.verifySuccess && onBoardingCompleted === false && pathname !== PATH_AUTH.welcome) {
    return <Navigate to={PATH_AUTH.welcome} />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
