import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Checkbox, FormGroup, FormControlLabel } from '@mui/material';

// ----------------------------------------------------------------------

RHFCheckbox.propTypes = {
  name: PropTypes.string,
  onCustomChange: PropTypes.func,
  size: PropTypes.string,
};

export function RHFCheckbox({ name, onCustomChange, size, ...other }) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Checkbox
              {...field}
              size={size}
              checked={field.value}
              onChange={(e) => {
                field.onChange(e);

                if (onCustomChange) {
                  onCustomChange(e);
                }
              }}
            />
          )}
        />
      }
      {...other}
    />
  );
}

// ----------------------------------------------------------------------

RHFMultiCheckbox.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  getOptionLabel: PropTypes.arrayOf(PropTypes.string),
  allButton: PropTypes.bool,
};

export function RHFMultiCheckbox({ name, options, getOptionLabel, allButton, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const onSelected = (option) =>
          field.value.includes(option) ? field.value.filter((value) => value !== option) : [...field.value, option];

        const numSelected = field.value.length;
        const itemCount = options.length;

        return (
          <FormGroup>
            {allButton && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={itemCount > 0 && numSelected === itemCount}
                    indeterminate={numSelected > 0 && numSelected < itemCount}
                    onChange={(event) => {
                      if (event.target.checked) {
                        field.onChange(options);
                        return;
                      }
                      field.onChange([]);
                    }}
                  />
                }
                label="All"
                {...other}
              />
            )}
            {options.map((option, index) => (
              <FormControlLabel
                key={option}
                control={
                  <Checkbox
                    checked={field.value.includes(option)}
                    onChange={() => field.onChange(onSelected(option))}
                  />
                }
                label={getOptionLabel?.length ? getOptionLabel[index] : option}
                {...other}
              />
            ))}
          </FormGroup>
        );
      }}
    />
  );
}
