import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation, Outlet } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import SignedInLogoLayout from '../layouts/SignedInLogoLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import OnboardingGuard from '../guards/OnboardingGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// context providers
import { ProductProvider } from '../contexts/ProductContext';
import { DonationProvider } from '../contexts/DonationContext';
import { DonorProvider } from '../contexts/DonorContext';
import { OwnerProvider } from '../contexts/OwnerContext';
import { StaffProvider } from '../contexts/StaffContext';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Auth Routes
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'login/sso',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register/:plan',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: 'welcome',
          element: (
            <OnboardingGuard>
              <SignedInLogoLayout />
              <Welcome />
            </OnboardingGuard>
          ),
        },
        {
          path: 'verify-success',
          element: <VerifySuccess />,
        },
        {
          path: 'forgot-password',
          element: (
            <GuestGuard>
              <ForgotPassword />
            </GuestGuard>
          ),
        },
        { path: 'handle-email-action', element: <HandleEmailAction /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <ProductProvider>
            <DonationProvider>
              <DonorProvider>
                <OwnerProvider>
                  <StaffProvider>
                    <DashboardLayout />
                  </StaffProvider>
                </OwnerProvider>
              </DonorProvider>
            </DonationProvider>
          </ProductProvider>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: '/dashboard', element: <Navigate to="/dashboard/general" replace />, index: true },
        { path: 'general', element: <GeneralDashboard /> },
        {
          path: 'products',
          children: [
            { element: <Navigate to="/dashboard/products/available-list" replace />, index: true },
            { path: 'available-list', element: <AvailableBloodProductList /> },
            { path: 'new', element: <BloodProductSingleCreate /> },
            { path: 'records', element: <BloodProductRecords /> },
            { path: 'details/:productId', element: <BloodProductDetails /> },
            { path: 'edit/:productId', element: <BloodProductEdit /> },
            { path: 'dispense/:dispenseId', element: <BloodProductDispensingDetails /> },
            { path: 'dispense/edit/:dispenseId', element: <BloodProductDispenseEdit /> },
          ],
        },
        {
          path: 'donations',
          children: [
            { element: <Navigate to="/dashboard/donations/scheduled" replace />, index: true },
            { path: 'scheduled', element: <ScheduledDonations /> },
            { path: 'current/:donationId', element: <CurrentDonationForm /> },
            { path: 'details/:donationId', element: <DonationDetails /> },
            { path: 'edit/:donationId', element: <DonationEdit /> },
            { path: 'records', element: <DonationRecords /> },
          ],
        },
        {
          path: 'donors',
          children: [
            { element: <Navigate to="/dashboard/donors/list" replace />, index: true },
            { path: 'list', element: <DonorList /> },
            { path: 'new', element: <DonorRegistration /> },
            { path: 'profile/:donorId', element: <DonorProfile /> },
            { path: 'edit/:donorId', element: <DonorEdit /> },
          ],
        },
        {
          path: 'owners',
          children: [
            { element: <Navigate to="/dashboard/owners/list" replace />, index: true },
            { path: 'list', element: <OwnerList /> },
            // { path: 'new', element: <DonorRegistration /> },
            { path: 'profile/:ownerId', element: <OwnerProfile /> },
            { path: 'edit/:ownerId', element: <OwnerEdit /> },
          ],
        },
        {
          path: 'admin',
          children: [
            { element: <Navigate to="/dashboard/admin/labels" replace />, index: true },
            { path: 'labels', element: <ProductLabels /> },
            { path: 'labels/:donationId', element: <ProductLabels /> },
          ],
        },
        {
          path: 'staffs',
          children: [
            { element: <Navigate to="/dashboard/staffs/list" replace />, index: true },
            { path: 'list', element: <StaffList /> },
          ],
        },
        {
          path: 'settings',
          children: [
            { element: <Navigate to="/dashboard/settings/account" replace />, index: true },
            { path: 'account', element: <AccountSettings /> },
            { path: 'organisation', element: <OrganisationSettings /> },
            { path: 'organisation/logo', element: <OrganisationSettings /> },
          ],
        },
        {
          path: 'billing',
          children: [
            { element: <Navigate to="/dashboard/billing/portal" replace />, index: true },
            {
              path: 'portal',
              element: (
                <RoleBasedGuard hasContent roles={['team-admin']}>
                  <BillingPortal />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'integrations',
          element: (
            <RoleBasedGuard hasContent roles={['team-admin']}>
              <Outlet />
            </RoleBasedGuard>
          ),
          children: [
            { element: <Navigate to="/dashboard/integrations/api" replace />, index: true },
            { path: 'api', element: <Integration /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'contact-us', element: <Contact /> },
        { path: 'contact-us/demo', element: <Contact /> },
        { path: 'about-us', element: <About /> },
        { path: 'plans', element: <Plans /> },
        { path: 'privacy', element: <PrivacyPolicy /> },
        { path: 'terms', element: <TermsAndConditions /> },
        // { path: 'faqs', element: <Faqs /> },
      ],
    },
    { path: 'no-access', element: <NoAccess /> },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const ForgotPassword = Loadable(lazy(() => import('../pages/auth/ForgotPassword')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const VerifySuccess = Loadable(lazy(() => import('../pages/auth/VerifySuccess')));
const Welcome = Loadable(lazy(() => import('../pages/auth/Welcome')));
const HandleEmailAction = Loadable(lazy(() => import('../pages/auth/HandleEmailAction')));

// Dashboard
const GeneralDashboard = Loadable(lazy(() => import('../pages/dashboard/GeneralDashboard')));
const AvailableBloodProductList = Loadable(lazy(() => import('../pages/dashboard/AvailableBloodProductList')));
const BloodProductSingleCreate = Loadable(lazy(() => import('../pages/dashboard/BloodProductSingleCreate')));
const BloodProductRecords = Loadable(lazy(() => import('../pages/dashboard/BloodProductRecords')));
const BloodProductDetails = Loadable(lazy(() => import('../pages/dashboard/BloodProductDetails')));
const BloodProductEdit = Loadable(lazy(() => import('../pages/dashboard/BloodProductEdit')));
const BloodProductDispensingDetails = Loadable(lazy(() => import('../pages/dashboard/BloodProductDispensingDetails')));
const BloodProductDispenseEdit = Loadable(lazy(() => import('../pages/dashboard/BloodProductDispenseEdit')));
const ScheduledDonations = Loadable(lazy(() => import('../pages/dashboard/ScheduledDonations')));
const DonationRecords = Loadable(lazy(() => import('../pages/dashboard/DonationRecords')));
const DonationDetails = Loadable(lazy(() => import('../pages/dashboard/DonationDetails')));
const DonationEdit = Loadable(lazy(() => import('../pages/dashboard/DonationEdit')));
const CurrentDonationForm = Loadable(lazy(() => import('../pages/dashboard/CurrentDonationForm')));
const DonorList = Loadable(lazy(() => import('../pages/dashboard/DonorList')));
const ProductLabels = Loadable(lazy(() => import('../pages/dashboard/ProductLabels')));
const DonorProfile = Loadable(lazy(() => import('../pages/dashboard/DonorProfile')));
const OwnerList = Loadable(lazy(() => import('../pages/dashboard/OwnerList')));
const OwnerProfile = Loadable(lazy(() => import('../pages/dashboard/OwnerProfile')));
const OwnerEdit = Loadable(lazy(() => import('../pages/dashboard/OwnerEdit')));
const DonorRegistration = Loadable(lazy(() => import('../pages/dashboard/DonorRegistration')));
const DonorEdit = Loadable(lazy(() => import('../pages/dashboard/DonorEdit')));
const StaffList = Loadable(lazy(() => import('../pages/dashboard/StaffList')));
const AccountSettings = Loadable(lazy(() => import('../pages/dashboard/AccountSettings')));
const OrganisationSettings = Loadable(lazy(() => import('../pages/dashboard/OrganisationSettings')));
const BillingPortal = Loadable(lazy(() => import('../pages/dashboard/BillingPortal')));
const Integration = Loadable(lazy(() => import('../pages/dashboard/Integration')));

// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Plans = Loadable(lazy(() => import('../pages/Plans')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/PrivacyPolicy')));
const TermsAndConditions = Loadable(lazy(() => import('../pages/TermsAndConditions')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const NoAccess = Loadable(lazy(() => import('../pages/NoAccess')));
