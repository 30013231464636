import { useMemo } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Menu } from '@mui/material';
// components
import FlagMenuItem from './FlagMenuItem';
// utils
import { filterCountries, getDisplayNames, sortAlphabeticallyCountryCodes } from '../../utils/telFieldHelpers';
import { ISO_CODES } from '../../constants/countries';

const FlagMenu = ({
  anchorEl,
  isoCode,
  onSelectCountry,
  excludedCountries = [],
  onlyCountries = [],
  langOfCountryName = 'en',
  continents = [],
  preferredCountries = [],
  className,
  flagSize = 'small',
  ...other
}) => {
  const displayNames = useMemo(() => getDisplayNames(langOfCountryName), [langOfCountryName]);

  const ISO_CODES_SORTED = sortAlphabeticallyCountryCodes(ISO_CODES, displayNames);

  const countriesFiltered = filterCountries(ISO_CODES_SORTED, {
    onlyCountries,
    excludedCountries,
    continents,
    preferredCountries,
  });

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      id="select-country"
      className={`MuiTelInput-Menu ${className || ''}`}
      MenuListProps={{
        role: 'listbox',
        'aria-activedescendant': isoCode ? `country-${isoCode}` : '',
        'aria-labelledby': 'select-country',
      }}
      sx={{ maxHeight: 300 }}
      {...other}
    >
      {countriesFiltered.map((isoCodeItem) => (
        <FlagMenuItem
          onSelectCountry={onSelectCountry}
          key={isoCodeItem}
          isoCode={isoCodeItem}
          countryName={displayNames.of(isoCodeItem)}
          selected={isoCodeItem === isoCode}
          id={`country-${isoCodeItem}`}
          flagSize={flagSize}
        />
      ))}
    </Menu>
  );
};
FlagMenu.propTypes = {
  anchorEl: PropTypes.object,
  isoCode: PropTypes.string,
  onSelectCountry: PropTypes.func,
  excludedCountries: PropTypes.array,
  onlyCountries: PropTypes.array,
  langOfCountryName: PropTypes.string,
  continents: PropTypes.array,
  preferredCountries: PropTypes.array,
  className: PropTypes.string,
  flagSize: PropTypes.string,
};

export default FlagMenu;
