import { useRef } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Button, AppBar, Toolbar, Container, Stack } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
// utils
import { bgBlur } from '../../utils/cssStyles';
// config
import { HEADER } from '../../config';
// routes
import { PATH_AUTH } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import Label from '../../components/Label';
//
import navConfig from './nav/config-navigation';
import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop/NavDesktop';

// ----------------------------------------------------------------------

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundImage: `linear-gradient(130deg, ${theme.palette.primary.main} 0%, #FFC837  60%, #FF8008  100%)`,
  backgroundSize: '200% auto',
  transition: '0.2s',
  '&:hover': {
    backgroundPosition: 'right center',
  },
}));

// ----------------------------------------------------------------------

export default function Header() {
  const carouselRef = useRef(null);

  const theme = useTheme();

  const isDesktop = useResponsive('up', 'md');

  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);

  return (
    <AppBar ref={carouselRef} color="transparent" sx={{ boxShadow: 0 }}>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.MOBILE_HEIGHT,
            md: HEADER.MAIN_DESKTOP_HEIGHT,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
        }}
      >
        <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
          <Logo />

          <Label color="primary" sx={{ ml: 1 }}>
            Blood Bank Registry
          </Label>

          <Box sx={{ flexGrow: 1 }} />

          {isDesktop && <NavDesktop isOffset={isOffset} data={navConfig} />}

          <Stack spacing={2} direction="row">
            <Button variant="outlined" color="inherit" component={RouterLink} to="/auth/login">
              Login
            </Button>

            <StyledButton
              variant="contained"
              component={RouterLink}
              to={PATH_AUTH.register}
              // to={PATH_PAGE.maintenance}
            >
              Start 1-month Free Trial
            </StyledButton>
          </Stack>

          {!isDesktop && <NavMobile isOffset={isOffset} data={navConfig} />}
        </Container>
      </Toolbar>

      {isOffset && <Shadow />}
    </AppBar>
  );
}

// ----------------------------------------------------------------------

Shadow.propTypes = {
  sx: PropTypes.object,
};

function Shadow({ sx, ...other }) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
