import { createContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { onSnapshot, collection, query, where, orderBy } from 'firebase/firestore';
// hooks
import useAuth from '../hooks/useAuth';
import { db } from '../utils/firebase';

const initialState = {
  donationIsInitialized: false,
  scheduledDonations: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE':
      return {
        ...state,
        scheduledDonations: action.payload.donations,
        donationIsInitialized: true,
      };

    default:
      return state;
  }
};

const DonationContext = createContext({
  ...initialState,
});

const DonationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user } = useAuth();
  const [initialised, setInitialised] = useState(false);

  const { institution } = user || {};

  useEffect(() => {
    if (!initialised) {
      return null;
    }

    const unsubsciber = onSnapshot(
      query(
        collection(db, 'institutions', institution, 'donations'),
        where('status', 'in', ['in_process', 'scheduled']),
        orderBy('startTime')
      ),
      (querySnapshot) => {
        const donations = [];
        querySnapshot.forEach((donation) => {
          donations.push({ ...donation.data(), id: donation.id });
        });

        console.log('Donation Context Called!');
        dispatch({
          type: 'INITIALISE',
          payload: { donations },
        });
      }
    );

    return () => unsubsciber();
  }, [institution, initialised]);

  const commenceDonationInitialisation = () => {
    setInitialised(true);
  };

  return (
    <DonationContext.Provider
      value={{
        ...state,
        commenceDonationInitialisation,
      }}
    >
      {children}
    </DonationContext.Provider>
  );
};

DonationProvider.propTypes = {
  children: PropTypes.node,
};

export { DonationContext, DonationProvider };
