import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Link } from '@mui/material';
// components
import Iconify from '../../../../components/Iconify';
//
import { ListItem } from './styles';

// ----------------------------------------------------------------------

export const NavItem = forwardRef(({ item, open, isOffset, active, subItem, isExternalLink, ...other }, ref) => {
  const { title, path, children } = item;

  const renderContent = (
    <ListItem ref={ref} disableRipple isOffset={isOffset} subItem={subItem} active={active} open={open} {...other}>
      {title}

      {!!children && <Iconify width={16} icon="eva:arrow-ios-downward-fill" sx={{ ml: 1 }} />}
    </ListItem>
  );

  // ExternalLink
  if (isExternalLink) {
    return (
      <Link href={path} target="_blank" rel="noopener" underline="none">
        {renderContent}
      </Link>
    );
  }

  // Has child
  if (children) {
    return renderContent;
  }

  // Default
  return (
    <Link component={RouterLink} to={path} underline="none">
      {renderContent}
    </Link>
  );
});

NavItem.propTypes = {
  open: PropTypes.bool,
  item: PropTypes.object,
  active: PropTypes.bool,
  subItem: PropTypes.bool,
  isOffset: PropTypes.bool,
  isExternalLink: PropTypes.bool,
};
