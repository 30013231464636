import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const random = Math.random().toString();

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg width="100%" height="100%" viewBox="0 0 231 328" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient
            x1="0"
            y1="0"
            x2="1"
            y2="0"
            id={random}
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(440.096,-440.096,440.096,440.096,-82.8977,384.549)"
          >
            <stop offset="0" stopColor="#ff6b6b" />
            <stop offset="0.29" stopColor="#ff6b6b" />
            <stop offset="0.66" stopColor="#ff7bac" />
            <stop offset="1" stopColor="#ff7bac" />
          </linearGradient>
        </defs>

        <g fillRule="evenodd" clipRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2">
          <path
            fill={`url(#${random})`}
            d="M107.853,6.322c-30.009,48.09 -60.07,96.147 -89.954,144.315c-4.66,7.509 -8.52,15.272 -11.516,23.328c-6.409,17.235 -7.857,35.887 -4.947,54.043c0.836,5.209 1.784,10.388 3.152,15.453c16.192,59.962 76.162,95.866 136.12,81.174c80.033,-19.611 115.559,-109.044 70.233,-177.495c-30.774,-46.475 -60.234,-93.82 -90.259,-140.789c-2.703,-4.227 -4.592,-6.351 -6.47,-6.351c-1.868,-0.001 -3.724,2.1 -6.359,6.322Z"
            fillRule="nonzero"
          />
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
