import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { onSnapshot, collection, query, where, orderBy, doc } from 'firebase/firestore';
// hooks
import useAuth from '../hooks/useAuth';
import { db } from '../utils/firebase';

const initialState = {
  productIsInitialized: false,
  statsIsInitialized: false,
  products: [],
  dispensedHistoryCount: {},
  productIDforLabel: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE':
      return {
        ...state,
        products: action.payload.products,
        productIsInitialized: true,
      };
    case 'INITIALISE_STATS':
      return {
        ...state,
        dispensedHistoryCount: action.payload.dispensedHistoryCount,
        statsIsInitialized: true,
      };
    case 'SET_PRODUCT_ID_FOR_LABEL':
      return {
        ...state,
        productIDforLabel: action.payload,
      };
    default:
      return state;
  }
};

const ProductContext = createContext({
  ...initialState,
  // setProductIDforLabel: () => {},
});

const ProductProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user } = useAuth();

  const { institution } = user || {};

  useEffect(() => {
    if (!institution) {
      return {};
    }

    const unsubsciber = onSnapshot(
      query(
        collection(db, 'institutions', institution, 'bloodProducts'),
        where('status', 'in', ['available', 'transfer_requested', 'on_hold', 'expired']),
        orderBy('expiryDate')
      ),
      (querySnapshot) => {
        const products = [];
        querySnapshot.forEach((product) => {
          products.push({ ...product.data(), id: product.id });
        });

        console.log('Product Context Called!');
        dispatch({
          type: 'INITIALISE',
          payload: { products },
        });
      }
    );

    return () => unsubsciber();
  }, [institution]);

  useEffect(() => {
    if (!institution) {
      return {};
    }

    const unsubsciber = onSnapshot(doc(db, 'institutions', institution, 'stats', 'dispensedHistoryCount'), (doc) => {
      dispatch({
        type: 'INITIALISE_STATS',
        payload: {
          dispensedHistoryCount: doc.data(),
        },
      });
    });

    return () => unsubsciber();
  }, [institution]);

  const setProductIDforLabel = ({ id }) => {
    dispatch({
      type: 'SET_PRODUCT_ID_FOR_LABEL',
      payload: id,
    });
  };

  return (
    <ProductContext.Provider
      value={{
        ...state,
        setProductIDforLabel,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

ProductProvider.propTypes = {
  children: PropTypes.node,
};

export { ProductContext, ProductProvider };
