const colourPickerList = [
  '#FF4842',
  '#FFC107',
  '#54D62C',
  '#3366FF',
  '#8ED1FC',
  '#7BDCB5',
  '#EB144C',
  '#F78DA7',
  '#9900EF',
  '#ABB8C3',
];

export default colourPickerList;
