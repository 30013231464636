import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// hooks
import useAuth from '../../hooks/useAuth';
import TelInput from '../TelInput';

// ----------------------------------------------------------------------

RHFPhoneNumberField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.string,
  defaultCountry: PropTypes.string,
};

export default function RHFPhoneNumberField({ name, helperText, defaultCountry, ...other }) {
  const { control } = useFormContext();
  const { institutionInfo } = useAuth();

  const country = defaultCountry || institutionInfo?.address?.country || undefined;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TelInput
          {...field}
          defaultCountry={country}
          continents={['NA', 'OC', 'EU']}
          preferredCountries={['AU', 'US']}
          error={!!error}
          helperText={error ? error.message : helperText || ''}
          {...other}
        />
      )}
    />
  );
}
