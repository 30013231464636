import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// material
import { Link, Stack, Alert, Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// firebase
import {
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { auth } from '../../../utils/firebase';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
// components
import { FormProvider, RHFTextField, RHFCheckbox, RHFPasswordField } from '../../hook-form';
// util
import authErrorParser from '../../../utils/firebaseAuthErrorParser';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { enqueueSnackbar } = useSnackbar();
  const { clearAuthError, authError, onLoginEmailEntered, signInWithOidc } = useAuth();
  const { pathname } = useLocation();

  const [SSOLogin, setSSOLogin] = useState(pathname.includes('sso') || false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: !SSOLogin ? Yup.string().required('Password is required') : null,
    remember: Yup.bool(),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    resetField,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  useEffect(() => {
    if (authError) {
      enqueueSnackbar(authError, {
        variant: 'error',
        onClose: () => clearAuthError(),
      });
    }
  }, [authError, clearAuthError, enqueueSnackbar]);

  const onSubmit = async (data) => {
    const { email, password, remember } = data;

    if (SSOLogin) {
      const { orgId, institutionId } = await onLoginEmailEntered(email);

      // if email domain recognised, login with SSO, otherwise change to password login
      if (orgId && institutionId) {
        await signInWithOidc(orgId, institutionId);
        return;
      }
      // Email domain not recognised, change to password login
      enqueueSnackbar('Email not recognised, please login with password', { variant: 'error' });

      setSSOLogin(false);
      resetField('password');
    } else {
      try {
        await setPersistence(auth, remember ? browserLocalPersistence : browserSessionPersistence);

        await signInWithEmailAndPassword(auth, email, password);
      } catch (error) {
        resetField('password');
        console.log(error.code);
        console.log(error.message);

        setError('afterSubmit', { message: authErrorParser(error.code) });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <Stack spacing={1}>
          <Typography variant="subtitle2">Email</Typography>

          <RHFTextField
            name="email"
            onCustomChange={() => {
              if (errors.afterSubmit) {
                clearErrors('afterSubmit');
              }
            }}
          />
        </Stack>

        {!SSOLogin && (
          <>
            <Stack spacing={1}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="subtitle2">Password</Typography>

                <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.forgotPassword}>
                  Forgot password?
                </Link>
              </Stack>

              <RHFPasswordField
                name="password"
                onCustomChange={() => {
                  if (errors.afterSubmit) {
                    clearErrors('afterSubmit');
                  }
                }}
              />
            </Stack>

            <RHFCheckbox name="remember" label="Remember me" />
          </>
        )}

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {SSOLogin ? 'Login with SSO' : 'Login'}
        </LoadingButton>
        <Box sx={{ display: 'flex', alignSelf: 'center' }}>
          <Link
            variant="subtitle2"
            component="button"
            type="button"
            underline="none"
            onClick={() => {
              setSSOLogin(!SSOLogin);
            }}
          >
            {SSOLogin ? 'Use your password instead' : 'Use single sign-on (SSO) instead'}
          </Link>
        </Box>
      </Stack>
    </FormProvider>
  );
}
