import { createContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { onSnapshot, collection, query } from 'firebase/firestore';
// hooks
import useAuth from '../hooks/useAuth';
import { db } from '../utils/firebase';

const initialState = {
  staffIsInitialized: false,
  staffIsLoaded: false,
  staffs: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE':
      return {
        ...state,
        staffs: action.payload.staffs,
        staffIsLoaded: true,
      };
    case 'FIRST_LOAD':
      return {
        ...state,
        staffIsInitialized: true,
      };
    default:
      return state;
  }
};

const StaffContext = createContext({
  ...initialState,
});

const StaffProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user } = useAuth();
  const [initialised, setInitialised] = useState(false);

  const { institution } = user || {};

  useEffect(() => {
    if (!initialised) {
      return null;
    }

    const unsubsciber = onSnapshot(
      query(collection(db, 'institutions', institution, 'staffs')),

      (querySnapshot) => {
        const staffs = [];
        querySnapshot.forEach((staff) => {
          staffs.push({ ...staff.data(), id: staff.id });
        });

        console.log('Staff Context Called!');
        dispatch({
          type: 'INITIALISE',
          payload: { staffs },
        });
      }
    );

    return () => unsubsciber();
  }, [institution, initialised]);

  const commenceStaffInitialisation = () => {
    setInitialised(true);
    dispatch({
      type: 'FIRST_LOAD',
    });
  };

  return (
    <StaffContext.Provider
      value={{
        ...state,
        commenceStaffInitialisation,
        //   createNewStaff
      }}
    >
      {children}
    </StaffContext.Provider>
  );
};

StaffProvider.propTypes = {
  children: PropTypes.node,
};

export { StaffContext, StaffProvider };
