import { useState, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import parsePhoneNumber from 'libphonenumber-js';
// @mui
import { InputAdornment, TextField } from '@mui/material';
// hooks
import usePhoneDigits from '../hooks/usePhoneDigits';
// components
import FlagButton from './tel-field/FlagButton';
import FlagMenu from './tel-field/FlagMenu';
import {
  assocRefToPropRef,
  getCallingCodeOfCountry,
  getValidCountry,
  putCursorAtEndOfInput,
  removeOccurrence,
} from '../utils/telFieldHelpers';

const TelInput = forwardRef((props, propRef) => {
  const {
    forceCallingCode = false,
    onlyCountries,
    excludedCountries,
    defaultCountry,
    onDoubleClick,
    onFocus,
    onCopy,
    onBlur,
    value = '',
    inputProps,
    InputProps,
    inputRef: inputRefFromProps,
    disabled,
    onChange,
    disableDropdown,
    disableFormatting = false,
    focusOnSelectCountry,
    langOfCountryName,
    continents,
    preferredCountries,
    MenuProps,
    className,
    flagSize = 'small',
    fieldSize,
    ...restTextFieldProps
  } = props;
  const textFieldRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const validDefaultCountry = forceCallingCode ? getValidCountry(defaultCountry) : defaultCountry;

  const { onInputChange, onCountryChange, inputRef, isoCode, inputValue } = usePhoneDigits({
    forceCallingCode,
    defaultCountry: validDefaultCountry,
    value: value ?? '',
    onChange,
    excludedCountries,
    onlyCountries,
    disableFormatting,
    continents,
  });

  const handleOpenFlagsMenu = (event) => {
    event.preventDefault();

    if (!disabled || !disableDropdown) {
      setAnchorEl(textFieldRef.current);
    }
  };

  const handleChangeCountry = (newCountry) => {
    setAnchorEl(null);
    onCountryChange(newCountry);

    if (focusOnSelectCountry && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleFocus = (event) => {
    setTimeout(() => {
      if (inputRef.current) {
        putCursorAtEndOfInput(inputRef.current);
      }
    }, 0);
    onFocus?.(event);
  };

  const handleDoubleClick = (event) => {
    const inputElement = inputRef.current;
    inputElement.setSelectionRange(0, inputElement.value.length);
    onDoubleClick?.(event);
  };

  const handleBlur = (event) => {
    const phoneNumber = parsePhoneNumber(inputValue, isoCode);

    if (phoneNumber && phoneNumber.isValid()) {
      onInputChange({ target: { value: phoneNumber.number } });
    }

    onBlur?.(event);
  };

  const handleCopy = (event) => {
    if (onCopy) {
      onCopy(event);

      return;
    }

    const currentSelection = window.getSelection();

    if (currentSelection) {
      const valueWithoutSpaces = currentSelection.toString().replaceAll(' ', '');
      event.clipboardData.setData('text/plain', valueWithoutSpaces);
      event.preventDefault();
    }
  };

  const handleRefInput = (ref) => {
    inputRef.current = ref;

    if (InputProps?.inputRef) {
      assocRefToPropRef(ref, InputProps.inputRef);
    }

    if (inputRefFromProps) {
      assocRefToPropRef(ref, inputRefFromProps);
    }
  };

  const handleRef = (ref) => {
    textFieldRef.current = ref;

    if (propRef) {
      assocRefToPropRef(ref, propRef);
    }
  };

  const handleCloseFlagsMenu = () => {
    setAnchorEl(null);
  };

  const isoCodeWithPlus = isoCode ? `+${getCallingCodeOfCountry(isoCode)}` : '';
  const validInputValue = forceCallingCode
    ? // We removed the isoCode but no necessarily the space after
      removeOccurrence(inputValue, isoCodeWithPlus).trimStart()
    : inputValue;

  return (
    <>
      <TextField
        type="tel"
        fullWidth
        size={fieldSize}
        disabled={disabled}
        value={validInputValue}
        onDoubleClick={handleDoubleClick}
        ref={handleRef}
        onBlur={handleBlur}
        inputRef={handleRefInput}
        className={`MuiTelInput-TextField ${className || ''}`}
        onChange={onInputChange}
        inputProps={{
          onCopy: handleCopy,
          ...inputProps,
        }}
        onFocus={handleFocus}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ flexShrink: 0 }}>
              <FlagButton
                isFlagsMenuOpened={Boolean(anchorEl)}
                isoCode={isoCode}
                forceCallingCode={forceCallingCode}
                onClick={handleOpenFlagsMenu}
                disabled={disabled}
                flagSize={flagSize}
                disableDropdown={Boolean(disableDropdown)}
              />
            </InputAdornment>
          ),
          ...InputProps,
        }}
        {...restTextFieldProps}
      />
      {!disableDropdown ? (
        <FlagMenu
          onlyCountries={onlyCountries}
          excludedCountries={excludedCountries}
          continents={continents}
          anchorEl={anchorEl}
          isoCode={isoCode}
          preferredCountries={preferredCountries}
          onClose={handleCloseFlagsMenu}
          langOfCountryName={langOfCountryName}
          onSelectCountry={handleChangeCountry}
          flagSize={flagSize}
          {...MenuProps}
        />
      ) : null}
    </>
  );
});

TelInput.propTypes = {
  forceCallingCode: PropTypes.bool,
  onlyCountries: PropTypes.array,
  excludedCountries: PropTypes.array,
  defaultCountry: PropTypes.string,
  onDoubleClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onCopy: PropTypes.func,
  value: PropTypes.string,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  inputRef: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  disableDropdown: PropTypes.bool,
  disableFormatting: PropTypes.bool,
  focusOnSelectCountry: PropTypes.bool,
  langOfCountryName: PropTypes.string,
  continents: PropTypes.array,
  preferredCountries: PropTypes.array,
  MenuProps: PropTypes.object,
  className: PropTypes.string,
  flagSize: PropTypes.string,
  fieldSize: PropTypes.string,
};

export default TelInput;
