import { useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { isEmpty } from 'lodash';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Stack, DialogTitle, DialogActions, Button, Alert, Typography, Collapse } from '@mui/material';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// firebase
import { reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { auth, functions } from '../../../utils/firebase';
// hooks
import useAuth from '../../../hooks/useAuth';
// components
import { FormProvider, RHFPasswordField, RHFTextField } from '../../../components/hook-form';
// util
import authErrorParser from '../../../utils/firebaseAuthErrorParser';

const ConfirmEmailForm = ({ onClose }) => {
  const { user, updateUserEmail } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [edittingEmail, setEdittingEmail] = useState(false);

  // Cloud Functions
  const sendEmailVerificationEmail = httpsCallable(functions, 'sendEmailVerificationEmail');

  const EmailSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const methods = useForm({
    resolver: edittingEmail ? yupResolver(EmailSchema) : null,
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const {
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    const { email, password } = data;

    try {
      if (!edittingEmail) {
        try {
          await sendEmailVerificationEmail({ email: user.email });

          onClose();
          enqueueSnackbar('Vertification Link Sent', { variant: 'success' });
        } catch (error) {
          console.error(error);
          enqueueSnackbar('Error! Please try again', { variant: 'error' });
        }
      }

      if (edittingEmail) {
        const credential = EmailAuthProvider.credential(user.email, password);

        await reauthenticateWithCredential(auth.currentUser, credential);
        await updateUserEmail(email);

        await sendEmailVerificationEmail({ email });

        onClose();
        enqueueSnackbar('Vertification Link Sent', { variant: 'success' });
      }
    } catch (error) {
      console.error('Firebase Error');

      setError('afterSubmit', { message: authErrorParser(error.code) });

      enqueueSnackbar('Error! Please try again', { variant: 'error' });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>Resend Verification Link</DialogTitle>

      <Stack spacing={3} sx={{ p: 3 }}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <Stack>
          <Typography variant="body2">Please confirm the following email address is correct</Typography>
          <Typography variant="subtitle1">{user.email}</Typography>
        </Stack>
        <Collapse in={edittingEmail} timeout="auto" unmountOnExit>
          <Stack spacing={2}>
            <RHFTextField
              name="email"
              label="Email Address"
              onCustomChange={() => {
                if (errors.afterSubmit) {
                  clearErrors('afterSubmit');
                }
              }}
            />
            <RHFPasswordField
              name="password"
              label="Password"
              helperText="Please re-enter your current password to update email address"
              onCustomChange={() => {
                if (errors.afterSubmit) {
                  clearErrors('afterSubmit');
                }
              }}
            />
          </Stack>
        </Collapse>
      </Stack>

      <DialogActions>
        <Button type="button" variant="outlined" color="inherit" onClick={onClose}>
          Back
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          type="button"
          variant="outlined"
          onClick={() => {
            setEdittingEmail(!edittingEmail);
            reset();
          }}
        >
          {edittingEmail ? 'Cancel' : 'Edit Email'}
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          disabled={!isEmpty(errors)}
          loading={isSubmitting}
          loadingIndicator="Loading..."
          style={{ minWidth: '6rem' }}
        >
          {edittingEmail ? 'Update email address & resend link' : 'Resend Link'}
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
};

ConfirmEmailForm.propTypes = {
  onClose: PropTypes.func,
};

export default ConfirmEmailForm;
