// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function UploadFileIllustration({ ...other }) {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_LIGHT = theme.palette.primary.light;

  return (
    <Box {...other}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 150.176 126.789">
        <g id="uploadFiles" transform="translate(-885.569 -377.884)" opacity="0.8">
          <path
            id="path_2316"
            data-name="path 2316"
            d="M209.906,198.589H164.132l-14.952-14.3a1.041,1.041,0,0,0-.7-.279h-32.41a4.074,4.074,0,0,0-4.08,4.08v75.475a4.075,4.075,0,0,0,4.083,4.08h93.837a4.074,4.074,0,0,0,4.08-4.08V202.673a4.075,4.075,0,0,0-4.083-4.08Z"
            transform="translate(799.941 215.263)"
            fill="#DFE3E8"
          />
          <g id="thick_9185" data-name="thick 9185" transform="translate(1024.871 463.302)">
            <g id="thick_9175" data-name="thick 9175" transform="translate(0 0)">
              <path
                id="path_2292"
                data-name="path 2292"
                d="M35.237,306.175a5.437,5.437,0,1,1,5.437-5.437A5.475,5.475,0,0,1,35.237,306.175Zm0-9.006a3.568,3.568,0,1,0,3.568,3.568A3.59,3.59,0,0,0,35.237,297.169Z"
                transform="translate(-29.8 -295.3)"
                fill={PRIMARY_LIGHT}
              />
            </g>
          </g>
          <path
            id="path_2298"
            data-name="path 2298"
            d="M14.729,1.546s6.61,4.318,19.9,16a35.524,35.524,0,0,1,.817,4.626c-2.076,7.466-11.647.8-7.632-3.708s15.95-1.063,16.7,13.857S37.705,64.826,0,77.284"
            transform="translate(976.801 412.723) rotate(3)"
            fill="none"
            stroke="#c2c8d8"
            strokeWidth="1"
            strokeDasharray="2"
          />
          <g id="thick_9180" data-name="thick 9180" transform="translate(903.755 413.362)">
            <g id="thick_9179" data-name="thick 9179">
              <path
                id="path_2294"
                data-name="path 2294"
                d="M673.566,347.632l-8.266-8.266,8.266-8.266,8.266,8.266Zm-5.377-8.266,5.457,5.457,5.457-5.457-5.457-5.457Z"
                transform="translate(-665.3 -331.1)"
                fill="#c2c8d8"
              />
            </g>
          </g>
          <path
            id="joint_11"
            data-name="joint 11"
            d="M4.225,8.6a1,1,0,0,1-1-1V5.374H1a1,1,0,0,1-1-1v-.15a1,1,0,0,1,1-1H3.225V1a1,1,0,0,1,1-1h.15a1,1,0,0,1,1,1V3.224H7.6a1,1,0,0,1,1,1v.15a1,1,0,0,1-1,1H5.375V7.6a1,1,0,0,1-1,1Z"
            transform="translate(992.18 378.885)"
            fill={PRIMARY_MAIN}
          />
          <path
            id="joint_12"
            data-name="joint 12"
            d="M5.619,12.319a1,1,0,0,1-1-1V7.7H1a1,1,0,0,1-1-1V5.618a1,1,0,0,1,1-1H4.619V1a1,1,0,0,1,1-1H6.7a1,1,0,0,1,1,1V4.618h3.619a1,1,0,0,1,1,1V6.7a1,1,0,0,1-1,1H7.7v3.622a1,1,0,0,1-1,1Z"
            transform="translate(943.232 492.354)"
            fill="#c2c8d8"
          />
          <path
            id="joint_13"
            data-name="joint 13"
            d="M4.556,9.483a1,1,0,0,1-1-1V5.927H1a1,1,0,0,1-1-1V4.555a1,1,0,0,1,1-1H3.556V1a1,1,0,0,1,1-1h.371a1,1,0,0,1,1,1V3.555H8.483a1,1,0,0,1,1,1v.371a1,1,0,0,1-1,1H5.927V8.483a1,1,0,0,1-1,1Z"
            transform="translate(892.981 391.683)"
            fill="#f1f3f8"
          />
          <path
            id="path_2315"
            data-name="path 2315"
            d="M197.21,76.038a1.7,1.7,0,0,1,.5,1.21v33.038A1.713,1.713,0,0,1,196,112H161.714A1.713,1.713,0,0,1,160,110.286V65.714A1.713,1.713,0,0,1,161.714,64h22.751a1.729,1.729,0,0,1,1.218.5l11.529,11.535Zm-3.45,2-10.082-10.08V78.036H193.76Zm-11.509,8.137-3.279,5.478-3.313-5.481a.642.642,0,0,0-.549-.311h-2.06a.645.645,0,0,0-.544.986l4.411,6.987-4.471,7.113a.64.64,0,0,0,.544.986h1.845a.645.645,0,0,0,.549-.3l3.358-5.437,3.337,5.435a.643.643,0,0,0,.549.306h2.007a.643.643,0,0,0,.541-.99l-4.491-6.994,4.571-7.1a.645.645,0,0,0-.542-.99H182.8a.643.643,0,0,0-.552.312Z"
            transform="translate(715.005 484.52) rotate(-30)"
            fill={PRIMARY_MAIN}
          />
          <path
            id="path_2318"
            data-name="path 2318"
            d="M88.533,112.533h25.6v-11.2h3.2v12.8a1.6,1.6,0,0,1-1.6,1.6h-28.8a1.6,1.6,0,0,1-1.6-1.6v-12.8h3.2v11.2Zm16-16v9.6h-6.4v-9.6h-8l11.2-11.2,11.2,11.2h-8Z"
            transform="translate(861.593 343.559)"
            fill="#fff"
          />
          <path
            id="path_2319"
            data-name="path 2319"
            d="M189.018,110.868a11.548,11.548,0,0,0-6.6-10.434V93.594a2.617,2.617,0,0,0-.622-1.7l-6.29-7.455a1.318,1.318,0,0,0-2.021,0L167.2,91.895a2.642,2.642,0,0,0-.623,1.7v6.841a11.546,11.546,0,0,0-6.6,10.434h6.457a3.216,3.216,0,0,0-.144.982,4.006,4.006,0,0,0,2.662,3.771,6.031,6.031,0,0,0,11.095,0,3.989,3.989,0,0,0,2.662-3.767,3.791,3.791,0,0,0-.129-.982l6.445,0ZM174.5,95.026a1.981,1.981,0,1,1-1.933,1.98A1.981,1.981,0,0,1,174.5,95.026Zm4.79,17.832a1.111,1.111,0,0,1-.706.141l-.8-.1-.116.8a3.2,3.2,0,0,1-6.329,0l-.115-.805-.8.1a1.143,1.143,0,0,1-.706-.144,1.162,1.162,0,0,1-.581-1.007,1.086,1.086,0,0,1,.6-.981h9.543a1.089,1.089,0,0,1,.6.981,1.17,1.17,0,0,1-.587,1.012Z"
            transform="translate(781.382 455.523) rotate(-46)"
            fill="#697391"
          />
        </g>
      </svg>
    </Box>
  );
}
