import PropTypes from 'prop-types';
import spacetime from 'spacetime';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormControl, Select, InputLabel, FormHelperText, MenuItem } from '@mui/material';
// util
import timezoneList from '../../utils/timezoneList';

// ----------------------------------------------------------------------

RHFTimezone.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  customOnChange: PropTypes.func,
  variant: PropTypes.string,
};

export default function RHFTimezone({ name, label, customOnChange, variant = 'outlined', ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl variant={variant} fullWidth>
          <InputLabel id={`${label}-label`} error={!!error}>
            {label}
          </InputLabel>
          <Select
            {...field}
            onChange={(event) => {
              field.onChange(event);

              if (customOnChange) {
                customOnChange(event);
              }
            }}
            fullWidth
            labelId={`${label}-label`}
            label={label}
            error={!!error}
            MenuProps={{ PaperProps: { sx: { maxHeight: '280px' } } }}
            {...other}
          >
            {Object.entries(timezoneList).map((zone) => {
              const now = spacetime.now(zone[0]);
              const tz = now.timezone();

              const min = tz.current.offset * 60;
              // eslint-disable-next-line no-bitwise
              const hr = `${(min / 60) ^ 0}:${min % 60 === 0 ? '00' : Math.abs(min % 60)}`;

              const label = `(GMT${hr.includes('-') ? hr : `+${hr}`}) ${zone[1]}`;

              return (
                <MenuItem value={zone[0]} key={zone[0]}>
                  {label}
                </MenuItem>
              );
            })}
          </Select>
          {!!error && <FormHelperText error>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}
