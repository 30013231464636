const authErrorParser = (errorType) => {
  switch (errorType) {
    case 'auth/user-not-found':
      return 'Error! User email / password incorrect';
    case 'auth/user-mismatch':
      return 'Error! User email / password incorrect';
    case 'auth/wrong-password':
      return 'Error! User email / password incorrect';
    case 'auth/invalid-email':
      return 'Error! Invalid email';
    case 'auth/email-already-in-use':
      return 'Error! Email address is already used';
    case 'auth/too-many-requests':
      return 'Too many failed login attempts, please try again later';
    case 'auth/user-disabled':
      return 'Error! Account disabled. Please contact your blood bank leader';
    default:
      return 'Error, please try again.';
  }
};

export default authErrorParser;
