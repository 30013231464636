import { useState } from 'react';
import PropTypes from 'prop-types';
import { endOfMonth, endOfYear, startOfMonth, startOfYear, subDays, subMonths, subYears } from 'date-fns';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Button, TextField, Stack, Typography, Chip } from '@mui/material';
import { StaticDatePicker } from '@mui/lab';
// components
import Iconify from '../Iconify';
import MenuPopover from '../MenuPopover';

const CalendarComponent = ({ title, name, control, customOnChange, otherProps }) => (
  <Stack>
    <Typography variant="subtitle2" sx={{ pl: 3, pt: 1 }} color="text.secondary">
      {title}
    </Typography>
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <StaticDatePicker
          {...field}
          onAccept={(event) => {
            field.onChange(event);

            if (customOnChange) {
              customOnChange(event);
            }
          }}
          onChange={() => {}}
          showDaysOutsideCurrentMonth
          displayStaticWrapperAs="desktop"
          views={['year', 'month', 'day']}
          renderInput={(params) => <TextField {...params} />}
          {...otherProps}
        />
      )}
    />
  </Stack>
);

CalendarComponent.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  control: PropTypes.object,
  customOnChange: PropTypes.func,
  otherProps: PropTypes.object,
};

const RHFTableDateRangeFilterButton = ({
  groupName,
  startDateName,
  customOnStartChange,
  startDateProps,
  endDateName,
  customOnEndChange,
  endDateProps,
}) => {
  const { control, setValue } = useFormContext();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handle14days = () => {
    setValue(startDateName, subDays(new Date(), 14));
    setValue(endDateName, new Date());
    customOnStartChange();
  };

  const handleCurrentMonth = () => {
    setValue(startDateName, startOfMonth(new Date()));
    setValue(endDateName, endOfMonth(new Date()));
    customOnStartChange();
  };

  const handleLastMonth = () => {
    setValue(startDateName, startOfMonth(subMonths(new Date(), 1)));
    setValue(endDateName, endOfMonth(subMonths(new Date(), 1)));
    customOnStartChange();
  };

  const handleLast3Month = () => {
    setValue(startDateName, startOfMonth(subMonths(new Date(), 3)));
    setValue(endDateName, endOfMonth(new Date()));
    customOnStartChange();
  };

  const handleCurrentYear = () => {
    setValue(startDateName, startOfYear(new Date()));
    setValue(endDateName, new Date());
    customOnStartChange();
  };

  const handleLastYear = () => {
    setValue(startDateName, startOfYear(subYears(new Date(), 1)));
    setValue(endDateName, endOfYear(subYears(new Date(), 1)));
    customOnStartChange();
  };

  return (
    <>
      <Button
        variant="text"
        endIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}
        sx={{ px: 2 }}
        onClick={handleOpen}
        color="inherit"
      >
        {groupName}
      </Button>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        disabledArrow
        sx={{ py: 2, px: 1, width: null }}
      >
        <Stack direction={{ md: 'row', xs: 'column' }}>
          <Stack sx={{ p: 2 }} spacing={1}>
            <Chip label="Last 14 days" onClick={handle14days} />
            <Chip label="Current month" onClick={handleCurrentMonth} />
            <Chip label="Last month" onClick={handleLastMonth} />
            <Chip label="Last 3 months" onClick={handleLast3Month} />
            <Chip label="Current year" onClick={handleCurrentYear} />
            <Chip label="Last year" onClick={handleLastYear} />
          </Stack>

          <CalendarComponent
            title="Start Date"
            name={startDateName}
            control={control}
            customOnChange={customOnStartChange}
            otherProps={startDateProps}
          />

          <Iconify icon="ic:round-arrow-right-alt" width={24} height={24} sx={{ alignSelf: 'center' }} />

          <CalendarComponent
            title="End Date"
            name={endDateName}
            control={control}
            customOnChange={customOnEndChange}
            otherProps={endDateProps}
          />
        </Stack>
      </MenuPopover>
    </>
  );
};

RHFTableDateRangeFilterButton.propTypes = {
  groupName: PropTypes.string,
  startDateName: PropTypes.string,
  customOnStartChange: PropTypes.func,
  startDateProps: PropTypes.object,
  endDateName: PropTypes.string,
  customOnEndChange: PropTypes.func,
  endDateProps: PropTypes.object,
};

export default RHFTableDateRangeFilterButton;
