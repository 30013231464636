import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { styled } from '@mui/material/styles';
import { FormControl, Select, InputLabel, FormHelperText } from '@mui/material';

// ----------------------------------------------------------------------

const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-outlined': {
    backgroundColor: theme.palette.common.white,
  },
}));

RHFStyledWhiteBGSelect.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  label: PropTypes.string,
  customOnChange: PropTypes.func,
  variant: PropTypes.string,
  size: PropTypes.string,
  sx: PropTypes.object,
};

export default function RHFStyledWhiteBGSelect({
  name,
  children,
  label,
  customOnChange,
  variant = 'outlined',
  size = 'small',
  sx,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl variant={variant} fullWidth sx={{ ...sx }}>
          <InputLabel id={`${label}-label`} error={!!error}>
            {label}
          </InputLabel>
          <StyledSelect
            {...field}
            onChange={(event) => {
              field.onChange(event);

              if (customOnChange) {
                customOnChange(event);
              }
            }}
            fullWidth
            labelId={`${label}-label`}
            label={label}
            error={!!error}
            size={size}
            {...other}
          >
            {children}
          </StyledSelect>
          {!!error && <FormHelperText error>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}
