import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Container, Typography, Stack, Button } from '@mui/material';
// hooks
import useAuth from '../../hooks/useAuth';
// components
import Page from '../../components/Page';
import Image from '../../components/Image';
import Iconify from '../../components/Iconify';
import { DialogAnimate } from '../../components/animate';
// section
import ConfirmEmailForm from '../../sections/auth/vertifyEmail/ConfirmEmailForm';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function VerifyEmail() {
  const { user, logout } = useAuth();

  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const handlePopupClose = () => {
    setPopupIsOpen(false);
  };

  return (
    <Page title="Verify Account">
      <Container>
        <ContentStyle sx={{ textAlign: 'center' }}>
          <Stack spacing={3} alignItems="center">
            <Box sx={{ width: '40%', p: 3 }}>
              <Image visibleByDefault disabledEffect alt="Email Verification" src="/assets/illustration_email.svg" />
            </Box>

            <Typography variant="h3" paragraph>
              Please check your email
            </Typography>

            <Typography sx={{ color: 'text.secondary' }}>
              We have sent a verification email to <strong>{user?.email}</strong>.
            </Typography>

            <Typography sx={{ color: 'text.secondary' }}>
              Please click the verification link or paste the link into your browser to verify your email address.
            </Typography>

            <Button
              variant="contained"
              onClick={() => {
                setPopupIsOpen(true);
              }}
            >
              Resend link
            </Button>

            <Box>
              <Link
                component="button"
                onClick={() => logout()}
                variant="body2"
                color="text.primary"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Iconify icon="eva:arrow-ios-back-fill" sx={{ mr: 1 }} />
                <strong>Return to sign in</strong>
              </Link>
            </Box>
          </Stack>
        </ContentStyle>

        <DialogAnimate open={popupIsOpen} onClose={handlePopupClose} maxWidth="sm">
          <ConfirmEmailForm onClose={handlePopupClose} />
        </DialogAnimate>
      </Container>
    </Page>
  );
}
