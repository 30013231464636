import { memo } from 'react';
import PropTypes from 'prop-types';
// @mui
import { ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';
// components
import Flag from './Flag';
import { COUNTRIES } from '../../constants/countries';

const FlagMenuItem = ({ isoCode, onSelectCountry, countryName, flagSize = 'small', ...menuItemProps }) => {
  const handleClick = (event) => {
    event.preventDefault();
    onSelectCountry(isoCode);
  };

  return (
    <MenuItem
      {...menuItemProps}
      onClick={handleClick}
      role="option"
      data-testid={`option-${isoCode}`}
      className="MuiTelInput-MenuItem"
    >
      <ListItemIcon>
        <Flag size={flagSize} isoCode={isoCode} countryName={countryName} />
      </ListItemIcon>
      <ListItemText>{countryName}</ListItemText>
      <Typography variant="body2" color="text.secondary">
        +{COUNTRIES[isoCode]?.[0]}
      </Typography>
    </MenuItem>
  );
};

FlagMenuItem.propTypes = {
  isoCode: PropTypes.string,
  onSelectCountry: PropTypes.func,
  countryName: PropTypes.string,
  flagSize: PropTypes.string,
};

export default memo(FlagMenuItem);
