// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// stripe
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// @mui
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// contexts
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { AuthProvider } from './contexts/FirebaseContext';

//
import App from './App';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const options = {
  currency: 'aud',
  mode: 'setup',
  appearance: {
    theme: 'stripe',
    variables: {
      fontFamily: 'Public Sans, sans-serif',
      fontSizeBase: '15px',
      fontWeightNormal: '400',
      spacingUnit: '4px',
      borderRadius: '8px',
      colorPrimary: '#FF6B6B',
      colorDanger: '#FF4842',
      colorIconTabSelected: '#fff',
      spacingGridRow: '16px',
      colorTextPlaceholder: '#919EAB',
      spacingAccordionItem: '16px',
      colorTextSecondary: '#919EAB',
    },
    rules: {
      '.Input:focus': {
        borderWidth: '1px',
        borderColor: '#FF6B6B',
        boxShadow: 'none',
      },
      '.Label': {
        marginLeft: '4px',
        marginBottom: '8px',
        fontWeight: 500,
        fontSize: '0.875rem',
        lineHeight: 1.5,
        color: '#212B36',
      },
    },
  },
};

// ----------------------------------------------------------------------

ReactDOM.render(
  <AuthProvider>
    <HelmetProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CollapseDrawerProvider>
          <BrowserRouter>
            <Elements stripe={stripePromise} options={options}>
              <App />
            </Elements>
          </BrowserRouter>
        </CollapseDrawerProvider>
      </LocalizationProvider>
    </HelmetProvider>
  </AuthProvider>,
  document.getElementById('root')
);
