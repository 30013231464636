export const ConditionalWrapper = ({ condition, wrapper, children, originalWrapper }) => {
  if (originalWrapper) {
    if (condition) {
      return wrapper(children);
    }
    return originalWrapper(children);
  }

  if (condition) {
    return wrapper(children);
  }
  return children;
};
