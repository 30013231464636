// @mui
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
// components
import { alpha } from '@mui/material/styles';
import Iconify from './Iconify';

const SnackbarIcon = ({ icon, color }) => (
  <Box
    component="span"
    sx={{
      mr: 1.5,
      width: 40,
      height: 40,
      display: 'flex',
      borderRadius: 1.5,
      alignItems: 'center',
      justifyContent: 'center',
      color: `${color}.main`,
      bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
    }}
  >
    <Iconify icon={icon} width={24} height={24} />
  </Box>
);

SnackbarIcon.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error']),
};

export default SnackbarIcon;
