// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  dashboard: getIcon('ic_dashboard'),
  drop: getIcon('cn_drop'),
  bag: getIcon('cn_history'),
  cabinet: getIcon('cn_cabinet'),
  syringe: getIcon('cn_syringe'),
  settings: getIcon('cn_settings'),
  report: getIcon('cn_report'),
  qr: getIcon('cn_qr'),
  dog: getIcon('cn_dog'),
  team: getIcon('cn_team'),
  hosp: getIcon('cn_hosp'),
  account: getIcon('cn_account'),
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Dashboard',
    hideSubheader: true,
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.general, icon: ICONS.dashboard },
      {
        title: 'Available Products',
        path: PATH_DASHBOARD.products.availableList,
        icon: ICONS.drop,
      },
      {
        title: 'Product Records',
        path: PATH_DASHBOARD.products.records,
        icon: ICONS.bag,
      },
    ],
  },

  // DONATIONS
  // ----------------------------------------------------------------------
  {
    subheader: 'Donations',
    items: [
      { title: 'Scheduled Donations', path: PATH_DASHBOARD.donations.scheduled, icon: ICONS.syringe },
      { title: 'Donations Records', path: PATH_DASHBOARD.donations.records, icon: ICONS.cabinet },
      { title: 'Donor List', path: PATH_DASHBOARD.donors.list, icon: ICONS.dog },
      // { title: 'Owner List', path: PATH_DASHBOARD.owners.list, icon: ICONS.dog },
    ],
  },
  // ADMIN
  // ----------------------------------------------------------------------
  {
    subheader: 'Admin',
    items: [
      { title: 'Product Labels', path: PATH_DASHBOARD.admin.labels, icon: ICONS.qr },
      // { title: 'Reports', path: PATH_DASHBOARD.admin.reports, icon: ICONS.report },
      { title: 'Staffs List', path: PATH_DASHBOARD.staffs.list, icon: ICONS.team },
    ],
  },
];

export default sidebarConfig;
