import { createContext, useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { onSnapshot, collection, query } from 'firebase/firestore';
// hooks
import useAuth from '../hooks/useAuth';
import { db } from '../utils/firebase';

const initialState = {
  ownerIsInitialized: false,
  owners: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE':
      return {
        ...state,
        owners: action.payload.owners,
        ownerIsInitialized: true,
      };
    default:
      return state;
  }
};

const OwnerContext = createContext({
  ...initialState,
});

const OwnerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user } = useAuth();
  const [initialised, setInitialised] = useState(false);

  const { institution } = user || {};

  useEffect(() => {
    if (!initialised) {
      return null;
    }

    const unsubsciber = onSnapshot(query(collection(db, 'institutions', institution, 'owners')), (querySnapshot) => {
      const owners = [];
      querySnapshot.forEach((owner) => {
        owners.push({ ...owner.data(), id: owner.id });
      });

      console.log('Owner Context Called!');
      dispatch({
        type: 'INITIALISE',
        payload: { owners },
      });
    });

    return () => unsubsciber();
  }, [institution, initialised]);

  const commenceOwnerInitialisation = () => {
    setInitialised(true);
  };

  return (
    <OwnerContext.Provider
      value={{
        ...state,
        commenceOwnerInitialisation,
      }}
    >
      {children}
    </OwnerContext.Provider>
  );
};

OwnerProvider.propTypes = {
  children: PropTypes.node,
};

export { OwnerContext, OwnerProvider };
