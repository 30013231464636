// routes
import { PATH_PAGE } from '../../../routes/paths';
// components
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="eva:home-fill" />,
    path: '/',
  },
  {
    title: 'About Us',
    path: PATH_PAGE.about,
  },
  {
    title: 'Pricing',
    path: PATH_PAGE.plans,
  },
  {
    title: 'Contact Us',
    path: PATH_PAGE.contact,
  },
];

export default navConfig;
