import { createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
// hooks
import useLocalStorage from '../hooks/useLocalStorage';
import useAuth from '../hooks/useAuth';
// config
import { LIST_DEFAULTS } from '../config';

// ----------------------------------------------------------------------

const { availableProductList, donorList, staffList } = LIST_DEFAULTS;

const initialState = {
  availableProductList,
  donorList,
  staffList,
};

const SettingsContext = createContext(initialState);

SettingsProvider.propTypes = {
  children: PropTypes.node,
};

function SettingsProvider({ children }) {
  const { user, institutionInfo, authLoading } = useAuth();

  const defaultLocationSetting = institutionInfo?.locations?.lenght === 1 ? institutionInfo?.locations[0] : 'All';

  const parsedInitialState = {
    availableProductList: {
      ...initialState.availableProductList,
      location: user?.defaultLocation || defaultLocationSetting,
    },
    donorList: { ...initialState.donorList, location: user?.defaultLocation || defaultLocationSetting },
    staffList: { ...initialState.staffList, location: user?.defaultLocation || defaultLocationSetting },
  };

  const [settings, setSettings] = useLocalStorage('listFilters', {
    ...parsedInitialState,
  });

  useEffect(() => {
    if (!authLoading) {
      setSettings(parsedInitialState);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authLoading]);

  const onFilterChange = (list, field, value) => {
    setSettings({
      ...settings,
      [list]: {
        ...settings[list],
        [field]: value,
      },
    });
  };

  const onFilterCheckboxChange = (list, field, fieldName) => {
    const currentFieldSetting = settings[list][field];

    const selectedIndex = currentFieldSetting.indexOf(fieldName);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(currentFieldSetting, fieldName);
    } else if (selectedIndex === 0) {
      newSelected = [...currentFieldSetting.slice(1)];
    } else if (selectedIndex === currentFieldSetting.length - 1) {
      newSelected = [...currentFieldSetting.slice(0, -1)];
    } else if (selectedIndex > 0) {
      newSelected = [...currentFieldSetting.slice(0, selectedIndex), ...currentFieldSetting.slice(selectedIndex + 1)];
    }

    setSettings({
      ...settings,
      [list]: {
        ...settings[list],
        [field]: newSelected,
      },
    });
  };

  const resetFilters = (list) => {
    setSettings({
      ...settings,
      [list]: parsedInitialState[list],
    });
  };

  return (
    <SettingsContext.Provider
      value={{
        ...settings,
        onFilterChange,
        onFilterCheckboxChange,
        resetFilters,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export { SettingsProvider, SettingsContext };
