import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

const RootStyle = styled('span')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const SpanStyle = styled('span')(() => ({
  width: '1px',
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(100%)',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
}));

// ----------------------------------------------------------------------

const Flag = ({ isoCode, size = 'small', countryName = '' }) => {
  const isoCodeFormatted = isoCode?.toLowerCase() || '';
  const width = size === 'small' ? 40 : 80;

  return (
    <RootStyle>
      {!isoCode ? (
        <img src="/icons/unknown-flag.png" alt="unknown" width={width / 2} />
      ) : (
        <RootStyle>
          <source type="image/webp" srcSet={`https://flagcdn.com/w${width}/${isoCodeFormatted}.webp`} />
          <source type="image/png" srcSet={`https://flagcdn.com/w${width}/${isoCodeFormatted}.png`} />
          <img
            src={`https://flagcdn.com/w${width}/${isoCodeFormatted}.png`}
            width={width / 2}
            alt={countryName || 'unknown'}
            loading="lazy"
          />
        </RootStyle>
      )}

      {isoCode && <SpanStyle>{isoCode}</SpanStyle>}
    </RootStyle>
  );
};
Flag.propTypes = {
  countryName: PropTypes.string,
  isoCode: PropTypes.string,
  size: PropTypes.string,
};

export default Flag;
