import { useState } from 'react';
import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { styled } from '@mui/material/styles';
import { IconButton, InputAdornment, TextField } from '@mui/material';
// components
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.common.white,
  },
}));

RHFStyledWhiteBGPasswordField.propTypes = {
  name: PropTypes.string,
  onCustomBlur: PropTypes.func,
  onCustomChange: PropTypes.func,
  helperText: PropTypes.string,
  size: PropTypes.string,
};

export default function RHFStyledWhiteBGPasswordField({
  name,
  onCustomBlur,
  onCustomChange,
  helperText,
  size = 'small',
  ...other
}) {
  const { control } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <StyledTextField
          {...field}
          onBlur={(event) => {
            field.onBlur(event);

            if (onCustomBlur) {
              onCustomBlur(event);
            }
          }}
          onChange={(event) => {
            field.onChange(event);

            if (onCustomChange) {
              onCustomChange(event);
            }
          }}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
          error={!!error}
          helperText={error ? error.message : helperText || ''}
          size={size}
          {...other}
        />
      )}
    />
  );
}
