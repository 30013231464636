import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormHelperText, Input } from '@mui/material';

// ----------------------------------------------------------------------

RHFInput.propTypes = {
  name: PropTypes.string,
  onCustomBlur: PropTypes.func,
  onCustomChange: PropTypes.func,
  helperText: PropTypes.string,
};

export default function RHFInput({ name, onCustomBlur, onCustomChange, helperText, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <Input
            {...field}
            onBlur={(event) => {
              field.onBlur(event);

              if (onCustomBlur) {
                onCustomBlur(event);
              }
            }}
            onChange={(event) => {
              field.onChange(event);

              if (onCustomChange) {
                onCustomChange(event);
              }
            }}
            fullWidth
            disableUnderline
            error={!!error}
            {...other}
          />
          {(!!error || helperText) && (
            <FormHelperText error={!!error}>{error ? error.message : helperText || ''}</FormHelperText>
          )}
        </>
      )}
    />
  );
}
