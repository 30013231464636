import { Link as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { Box, Grid, Link, Stack, Divider, Container, Typography } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
// // _mock
// import { _socials } from '../../_mock/arrays';
// components
import Logo from '../../components/Logo';
// import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

// export const SOCIALS = [
//   {
//     value: 'facebook',
//     name: 'FaceBook',
//     icon: 'eva:facebook-fill',
//     color: '#1877F2',
//     path: 'https://www.facebook.com/',
//   },
//   {
//     value: 'instagram',
//     name: 'Instagram',
//     icon: 'ant-design:instagram-filled',
//     color: '#E02D69',
//     path: 'https://www.instagram.com/',
//   },
//   {
//     value: 'linkedin',
//     name: 'Linkedin',
//     icon: 'eva:linkedin-fill',
//     color: '#007EBB',
//     path: 'https://www.linkedin.com/',
//   },
//   {
//     value: 'twitter',
//     name: 'Twitter',
//     icon: 'eva:twitter-fill',
//     color: '#00AAEC',
//     path: 'https://www.twitter.com/',
//   },
// ];

const LINKS = [
  {
    headline: 'Blood Bank Registry',
    children: [
      { name: 'About us', href: PATH_PAGE.about },
      { name: 'Contact us', href: PATH_PAGE.contact },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Conditions', href: PATH_PAGE.terms },
      { name: 'Privacy Policy', href: PATH_PAGE.privacy },
    ],
  },
  {
    headline: 'Contact',
    children: [
      {
        name: 'hello@bloodbankregistry.com',
        href: '#',
        onClick: (e) => {
          window.location = 'mailto:hello@bloodbankregistry.com';
          e.preventDefault();
        },
      },
      {
        name: '104 Eastlake Street, Carrara, Australia',
      },
    ],
  },
];

// ----------------------------------------------------------------------

export default function Footer() {
  const { pathname } = useLocation();

  const isHome = pathname === '/';
  const isPricing = pathname === '/plans';

  const simpleFooter = (
    <Box
      component="footer"
      sx={{
        py: 5,
        textAlign: 'center',
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Container>
        <Logo sx={{ mb: 1, mx: 'auto' }} />

        <Typography variant="caption" component="p">
          © {new Date().getFullYear()} All rights reserved
          <br /> made by&nbsp;
          <Link href="https://www.bloodbankregistry.com/">Blood Bank Registry</Link>
        </Typography>
      </Container>
    </Box>
  );

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
          sx={{
            textAlign: {
              xs: 'center',
              md: 'left',
            },
          }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
          </Grid>

          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              The all-in-one solution for your veterinary blood bank, ready to be customised to your need.
            </Typography>

            {/* <Stack
              spacing={1}
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{
                mt: 5,
                mb: { xs: 5, md: 0 },
              }}
            >
              {_socials.map((social) => (
                <IconButton key={social.name}>
                  <Iconify icon={social.icon} />
                </IconButton>
              ))}
            </Stack> */}
            <Box
              sx={{
                mt: 5,
                mb: { xs: 5, md: 0 },
              }}
            />
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack spacing={5} justifyContent="space-between" direction={{ xs: 'column', md: 'row' }}>
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2} alignItems={{ xs: 'center', md: 'flex-start' }}>
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) =>
                    link.href ? (
                      <Link key={link.name} component={RouterLink} to={link.href} color="inherit" variant="body2">
                        {link.name}
                      </Link>
                    ) : (
                      <Typography variant="body2" key={link.name}>
                        {link.name}
                      </Typography>
                    )
                  )}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          variant="caption"
          component="div"
          sx={{
            mt: 10,
            pb: 5,
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          © {new Date().getFullYear()}. All rights reserved
        </Typography>
      </Container>
    </Box>
  );

  return isHome || isPricing ? simpleFooter : mainFooter;
}
