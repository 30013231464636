import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/lab';
// hooks
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

RHFDatePicker.propTypes = {
  name: PropTypes.string,
  inputFormat: PropTypes.string,
  views: PropTypes.array,
  customOnChange: PropTypes.func,
};

export default function RHFDatePicker({ name, views = ['year', 'month', 'day'], customOnChange, ...other }) {
  const { control } = useFormContext();
  const { institutionInfo } = useAuth();

  const { dateFormat = 'd/M/yyyy' } = institutionInfo || {};

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          {...field}
          onChange={(event) => {
            field.onChange(event);

            if (customOnChange) {
              customOnChange(event);
            }
          }}
          inputFormat={dateFormat}
          views={views}
          renderInput={(params) => <TextField {...params} error={!!error} helperText={error?.message} fullWidth />}
          {...other}
        />
      )}
    />
  );
}
