import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/lab';
// hooks
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.common.white,
  },
}));

RHFStyledWhiteBGDatePicker.propTypes = {
  name: PropTypes.string,
  inputFormat: PropTypes.string,
  size: PropTypes.string,
  views: PropTypes.array,
  customOnChange: PropTypes.func,
  placeholder: PropTypes.string,
  sx: PropTypes.object,
};

export default function RHFStyledWhiteBGDatePicker({
  name,
  views = ['year', 'month', 'day'],
  size = 'small',
  customOnChange,
  placeholder,
  sx,
  ...other
}) {
  const { control } = useFormContext();
  const { institutionInfo } = useAuth();

  const { dateFormat = 'd/M/yyyy' } = institutionInfo || {};

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          {...field}
          onChange={(event) => {
            field.onChange(event);

            if (customOnChange) {
              customOnChange(event);
            }
          }}
          inputFormat={dateFormat}
          views={views}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              inputProps={{ ...params.inputProps, placeholder }}
              error={!!error}
              helperText={error?.message}
              size={size}
              fullWidth
              sx={{ ...sx }}
            />
          )}
          {...other}
        />
      )}
    />
  );
}
