import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormControl, Select, InputLabel, FormHelperText } from '@mui/material';

// ----------------------------------------------------------------------

RHFSelect.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  label: PropTypes.string,
  customOnChange: PropTypes.func,
  variant: PropTypes.string,
  sx: PropTypes.object,
};

export default function RHFSelect({ name, children, label, customOnChange, variant = 'outlined', sx, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl variant={variant} fullWidth sx={{ ...sx }}>
          <InputLabel id={`${label}-label`} error={!!error}>
            {label}
          </InputLabel>
          <Select
            {...field}
            onChange={(event) => {
              field.onChange(event);

              if (customOnChange) {
                customOnChange(event);
              }
            }}
            fullWidth
            labelId={`${label}-label`}
            label={label}
            error={!!error}
            {...other}
          >
            {children}
          </Select>
          {!!error && <FormHelperText error>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}
