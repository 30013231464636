import { createContext, useEffect, useReducer, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { snakeCase } from 'change-case';
import { uniqWith, concat } from 'lodash';
import humanparser from 'humanparser';
// firebase
import { httpsCallable } from 'firebase/functions';
import { doc, onSnapshot, writeBatch, getDocs, collection, where, query, getDoc, Timestamp } from 'firebase/firestore';
import {
  getAuth,
  onAuthStateChanged,
  signOut as firebaseSignOut,
  sendPasswordResetEmail,
  updateEmail,
  OAuthProvider,
  signInWithPopup,
  getAdditionalUserInfo,
  getIdToken,
} from 'firebase/auth';
// utils
import { auth, db, functions } from '../utils/firebase';

const initialState = {
  isAuthenticated: false,
  authLoading: true,
  user: null,
  institutionInfo: null,
  activeSubscription: [],
  authError: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE':
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        authLoading: false,
      };
    case 'SET_INSTITUTION':
      return {
        ...state,
        institutionInfo: action.payload.institutionInfo,
      };
    case 'SET_SUBSCRIPTION':
      return {
        ...state,
        activeSubscription: action.payload.activeSubscription,
      };
    case 'AUTH_ERROR':
      return {
        ...state,
        authLoading: false,
        authError: action.payload.authError,
      };
    case 'AUTH_LOADING':
      return {
        ...state,
        authLoading: true,
      };
    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  register: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  checkPINAvailability: () => Promise.resolve(),
  onLoginEmailEntered: () => null,
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [profile, setProfile] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const firebaseAuth = getAuth();

  const unsubscriberInstitution = useRef();
  const unsubscriberUser = useRef();
  const unsubscriberBilling = useRef();

  const setNewUserCustomClaims = httpsCallable(functions, 'setNewUserCustomClaims');

  useEffect(() => {
    // Helper function to log user out
    const logUserOut = async (errorMessage) => {
      localStorage.clear();

      if (unsubscriberUser.current) {
        unsubscriberUser.current();
      }

      if (unsubscriberInstitution.current) {
        unsubscriberInstitution.current();
      }

      if (unsubscriberBilling.current) {
        unsubscriberBilling.current();
      }

      dispatch({
        type: 'AUTH_ERROR',
        payload: { authError: errorMessage },
      });

      await firebaseSignOut(auth);
    };

    // Helper function to get user info
    const getUserInfo = (user) => {
      try {
        const userDocRef = doc(db, 'users', user.uid);

        unsubscriberUser.current = onSnapshot(userDocRef, { includeMetadataChanges: true }, (userDoc) => {
          if (!userDoc.exists()) {
            console.log("Awaiting user's profile");

            // loguser out if user doc does not exist in 30 seconds
            setTimeout(() => {
              if (userDoc.exists()) {
                return;
              }

              logUserOut('Error! Please Try Again');
            }, 30000);
          } else {
            const { institution } = userDoc.data();

            // Get institution info
            if (institution) {
              const institutionDocRef = doc(db, 'institutions', institution);
              const subscriptionCollectionRef = collection(db, 'institutions', institution, 'subscriptions');

              unsubscriberInstitution.current = onSnapshot(institutionDocRef, (institutionDoc) => {
                // Logic for getting all blood product types
                const { canineBloodProductTypes, felineBloodProductTypes } = institutionDoc.data() || {};

                const customiser = (a, b) => a.productType === b.productType;

                const allBloodProductTypes = uniqWith(
                  concat(canineBloodProductTypes || [], felineBloodProductTypes || []),
                  customiser
                );

                dispatch({
                  type: 'SET_INSTITUTION',
                  payload: { institutionInfo: { ...institutionDoc.data(), allBloodProductTypes } },
                });
              });

              unsubscriberBilling.current = onSnapshot(
                query(subscriptionCollectionRef, where('status', 'in', ['active', 'trialing'])),
                (querySnapshot) => {
                  const activeSubscription = [];

                  querySnapshot.forEach((sub) => {
                    activeSubscription.push({ ...sub.data(), id: sub.id });
                  });

                  dispatch({
                    type: 'SET_SUBSCRIPTION',
                    payload: { activeSubscription },
                  });
                }
              );

              setProfile({
                ...userDoc.data(),
                verified: user.emailVerified,
                id: user.uid,
                providerData: user.providerData,
              });
              dispatch({
                type: 'INITIALISE',
                payload: { isAuthenticated: true },
              });
            } else {
              // Unable to get institution info in user doc
              logUserOut('Error! User info is incomplete. Please contact support');
            }
          }
        });
      } catch (error) {
        logUserOut('Error! Please Try Again');
      }
    };

    onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        dispatch({ type: 'AUTH_LOADING' });

        getUserInfo(user);
      } else {
        // No user or logged out
        localStorage.clear();

        if (unsubscriberUser.current) {
          unsubscriberUser.current();
        }

        if (unsubscriberInstitution.current) {
          unsubscriberInstitution.current();
        }

        if (unsubscriberBilling.current) {
          unsubscriberBilling.current();
        }

        setProfile(null);

        dispatch({
          type: 'INITIALISE',
          payload: { isAuthenticated: false },
        });

        dispatch({
          type: 'SET_INSTITUTION',
          payload: { institutionInfo: null },
        });
      }
    });

    // Cleaning up subscription
    return () => {
      if (unsubscriberUser.current) {
        unsubscriberUser.current();
      }

      if (unsubscriberInstitution.current) {
        unsubscriberInstitution.current();
      }

      if (unsubscriberBilling.current) {
        unsubscriberBilling.current();
      }
    };
  }, [dispatch, firebaseAuth]);

  const signInWithOidc = async (orgId, institutionId) => {
    const provider = new OAuthProvider(orgId);

    try {
      const result = await signInWithPopup(firebaseAuth, provider);

      const additionalInfo = getAdditionalUserInfo(result);

      if (additionalInfo.isNewUser) {
        // set custom claims
        const response = await setNewUserCustomClaims({
          uid: result.user.uid,
          role: 'staff',
          institution: institutionId,
        });

        if (response.data.success) {
          console.log('Custom claims set');

          // refresh token
          await getIdToken(result.user, true);

          // get list of locations and clinic from institusion doc
          const institutionDocRef = doc(db, 'institutions', institutionId);

          const institutionDoc = await getDoc(institutionDocRef);

          const { locations, clinics, timezone } = institutionDoc.data();

          // create user profile in firestore
          const batch = writeBatch(db);

          const userRef = doc(db, 'users', result.user.uid);
          const staffRef = doc(db, 'institutions', institutionId, 'staffs', result.user.uid);

          const { profile, providerId } = additionalInfo;

          const { name, email, zoneinfo, sub } = profile;

          const { firstName, lastName } = humanparser.parseName(name);

          const userObject = {
            active: true,
            role: 'staff',
            fullname: name,
            givenName: firstName,
            familyName: lastName,
            email,
            institution: institutionId,
            externalId: sub,
            authProviderId: providerId,
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
            timezone,
          };

          if (clinics) {
            userObject.defaultClinic = clinics[0];
          }

          if (locations) {
            userObject.defaultLocation = locations[0];
          }

          // add zoneinfo as timezone if available
          if (zoneinfo) {
            userObject.timezone = zoneinfo;
          }

          batch.set(userRef, userObject);
          batch.set(staffRef, userObject);

          await batch.commit();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const logout = async () => {
    await firebaseSignOut(firebaseAuth);
  };

  const resetPassword = async (email) => {
    await sendPasswordResetEmail(firebaseAuth, email);
  };

  const clearAuthError = () => {
    dispatch({
      type: 'AUTH_ERROR',
      payload: { authError: null },
    });
  };

  const checkPINAvailability = async (pin) => {
    try {
      const querySnapshot = await getDocs(
        query(collection(db, 'institutions', profile.institution, 'staffs'), where('pin', '==', pin))
      );

      if (querySnapshot.empty) {
        return true;
      }

      return false;
    } catch (error) {
      throw new Error(error);
    }
  };

  const checkFacilityName = async (facilityName) => {
    try {
      const parsedFacilityName = snakeCase(facilityName);

      const docSnap = await getDoc(doc(db, 'institution_names', parsedFacilityName));

      if (docSnap.exists()) {
        return false;
      }

      return true;
    } catch (error) {
      console.log(error);

      throw new Error(error);
    }
  };

  const updateUserEmail = async (newEmail) => {
    // * call reauthenticateWithCredential prior to this function
    await updateEmail(auth.currentUser, newEmail);

    const batch = writeBatch(db);
    batch.update(doc(db, 'users', profile.id), {
      email: newEmail,
    });

    if (profile.onBoardingCompleted !== undefined && profile.onBoardingCompleted === false) {
      batch.update(doc(db, 'onboarding', profile.id), {
        email: newEmail,
      });
    }

    await batch.commit();
  };

  const getDomainFromEmail = (email) => {
    const emailSplit = email.split('@');

    return emailSplit[1];
  };

  const onLoginEmailEntered = async (email) => {
    const domain = getDomainFromEmail(email);

    const querySnapshot = await getDocs(
      query(collection(db, 'sso_orgs'), where('domain', '==', domain), where('active', '==', true))
    );

    if (!querySnapshot.empty && querySnapshot?.docs[0]?.data()?.orgId) {
      const { orgId, institutionId } = querySnapshot.docs[0].data();

      return { orgId, institutionId };
    }

    return { orgId: null, institutionId: null };
  };

  const refreshUserEmailVerification = async () => {
    await firebaseAuth.currentUser.reload();

    const user = firebaseAuth.currentUser;

    setProfile({
      ...profile,
      providerData: user.providerData,
      verified: user.emailVerified,
    });

    return null;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: profile,
        // register,
        signInWithOidc,
        clearAuthError,
        logout,
        resetPassword,
        checkPINAvailability,
        updateUserEmail,
        refreshUserEmailVerification,
        checkFacilityName,
        onLoginEmailEntered,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
