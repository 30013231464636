import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Switch, FormControlLabel, Tooltip, Box } from '@mui/material';

// ----------------------------------------------------------------------

RHFSwitch.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  customOnChange: PropTypes.func,
  tooltip: PropTypes.string,
};

export default function RHFSwitch({ name, disabled, customOnChange, tooltip, ...other }) {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            if (tooltip) {
              return (
                <Tooltip title={tooltip}>
                  <Box>
                    <Switch
                      {...field}
                      onChange={(event) => {
                        field.onChange(event);
                        if (customOnChange) {
                          customOnChange(event);
                        }
                      }}
                      checked={field.value}
                      disabled={disabled}
                    />
                  </Box>
                </Tooltip>
              );
            }

            return (
              <Switch
                {...field}
                onChange={(event) => {
                  field.onChange(event);
                  if (customOnChange) {
                    customOnChange(event);
                  }
                }}
                checked={field.value}
                disabled={disabled}
              />
            );
          }}
        />
      }
      disabled={disabled}
      {...other}
    />
  );
}
