import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Stack, Typography, Link } from '@mui/material';
// hook
import useAuth from '../../hooks/useAuth';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// component
import Iconify from '../../components/Iconify';

const ExpiredSubscriptionBanner = () => {
  const { user } = useAuth();

  return (
    <Box sx={{ bgcolor: 'error.lighter', py: 0.5, px: 2, color: 'error.darker' }}>
      <Stack direction="row" justifyContent="center" alignItems="center" spacing={0.5}>
        <Iconify icon="ic:round-warning-amber" width={18} height={18} />
        <Typography variant="subtitle2">Subscription Expired: Limited Access</Typography>
        {user?.role === 'team-admin' && (
          <>
            <Typography> • </Typography>
            <Link
              sx={{
                ml: 'auto',
                flexDirection: 'row',
                display: 'flex',
                alignItems: 'center',
              }}
              to={PATH_DASHBOARD.billing.root}
              color="inherit"
              component={RouterLink}
            >
              <Typography variant="subtitle2" noWrap>
                Go to billing
              </Typography>
              <Iconify icon="eva:arrow-ios-forward-fill" />
            </Link>
          </>
        )}
      </Stack>
    </Box>
  );
};

export default ExpiredSubscriptionBanner;
