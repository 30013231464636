import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
// components
import Logo from '../components/Logo';
import AccountPopover from './components/AccountPopover';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4, 4, 0),
  },
}));

// ----------------------------------------------------------------------

const SignedInLogoLayout = () => (
  <>
    <HeaderStyle>
      <Stack direction="row" justifyContent="space-between">
        <Logo />
        <AccountPopover />
      </Stack>
    </HeaderStyle>
    <Outlet />
  </>
);

export default SignedInLogoLayout;
