import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { styled } from '@mui/material/styles';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';

// ----------------------------------------------------------------------

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.common.white,
  },
}));

RHFStyledWhiteBGAutocomplete.propTypes = {
  name: PropTypes.string,
  fetchingFunction: PropTypes.func,
  label: PropTypes.string,
  customOnChange: PropTypes.func,
  onChange: PropTypes.func,
  staticOptions: PropTypes.array,
  size: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  forcePopupIcon: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default function RHFStyledWhiteBGAutocomplete({
  name,
  fetchingFunction,
  customOnChange,
  onChange,
  label,
  staticOptions,
  placeholder,
  size = 'small',
  variant = 'outlined',
  forcePopupIcon = false,
  ...other
}) {
  const { control } = useFormContext();

  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState(staticOptions || []);
  const loading = staticOptions ? false : isOpen && options.length === 0;

  useEffect(() => {
    let active = true;

    // if options were provided, stop fetching
    if (staticOptions) {
      return null;
    }

    if (!loading) {
      return undefined;
    }

    (async () => {
      const data = await fetchingFunction();

      if (active) {
        setOptions(data);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, fetchingFunction, staticOptions]);

  useEffect(() => {
    // if options were provided, stop clearing
    if (staticOptions) {
      return;
    }

    if (!isOpen) {
      setOptions([]);
    }
  }, [isOpen, staticOptions]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          fullWidth
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          onChange={(event, newValue) => {
            if (onChange) {
              onChange(newValue);
            } else {
              field.onChange(newValue);
            }

            if (customOnChange) {
              customOnChange(newValue);
            }
          }}
          options={options}
          loading={loading}
          forcePopupIcon={forcePopupIcon}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              variant={variant}
              label={label}
              error={!!error}
              helperText={error?.message}
              size={size}
              placeholder={placeholder}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              fullWidth
            />
          )}
          {...other}
        />
      )}
    />
  );
}
