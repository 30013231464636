// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_AUTH = '/auth';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  verifySuccess: path(ROOTS_AUTH, '/verify-success'),
  welcome: path(ROOTS_AUTH, '/welcome'),
};

export const PATH_PAGE = {
  root: '/',
  features: '/features',
  about: '/about-us',
  contact: '/contact-us',
  requestDemo: '/contact-us/demo',
  plans: '/plans',

  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  privacy: '/privacy',
  terms: '/terms',
  // payment: '/payment',

  faqs: '/faqs',
  page404: '/404',
  noAccess: '/no-access',
  // page500: '/500',
  // components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    general: path(ROOTS_DASHBOARD, '/general'),
  },
  products: {
    root: path(ROOTS_DASHBOARD, '/products'),
    availableList: path(ROOTS_DASHBOARD, '/products/available-list'),
    new: path(ROOTS_DASHBOARD, '/products/new'),
    records: path(ROOTS_DASHBOARD, '/products/records'),
    details: path(ROOTS_DASHBOARD, '/products/details'),
    edit: path(ROOTS_DASHBOARD, '/products/edit'),
    dispense: path(ROOTS_DASHBOARD, '/products/dispense'),
    dispenseEdit: path(ROOTS_DASHBOARD, '/products/dispense/edit'),
  },
  transfers: {
    root: path(ROOTS_DASHBOARD, '/transfers'),
    active: path(ROOTS_DASHBOARD, '/transfers/active'),
    records: path(ROOTS_DASHBOARD, '/transfers/records'),
  },
  donations: {
    root: path(ROOTS_DASHBOARD, '/donations'),
    scheduled: path(ROOTS_DASHBOARD, '/donations/scheduled'),
    records: path(ROOTS_DASHBOARD, '/donations/records'),
    current: path(ROOTS_DASHBOARD, '/donations/current'),
    details: path(ROOTS_DASHBOARD, '/donations/details'),
    edit: path(ROOTS_DASHBOARD, '/donations/edit'),
  },
  donors: {
    root: path(ROOTS_DASHBOARD, '/donors'),
    list: path(ROOTS_DASHBOARD, '/donors/list'),
    new: path(ROOTS_DASHBOARD, '/donors/new'),
    profile: path(ROOTS_DASHBOARD, '/donors/profile'),
    edit: path(ROOTS_DASHBOARD, '/donors/edit'),
  },
  owners: {
    root: path(ROOTS_DASHBOARD, '/owners'),
    list: path(ROOTS_DASHBOARD, '/owners/list'),
    new: path(ROOTS_DASHBOARD, '/owners/new'),
    profile: path(ROOTS_DASHBOARD, '/owners/profile'),
    edit: path(ROOTS_DASHBOARD, '/owners/edit'),
  },
  admin: {
    root: path(ROOTS_DASHBOARD, '/admin'),
    settings: path(ROOTS_DASHBOARD, 'admin/settings'),
    labels: path(ROOTS_DASHBOARD, '/admin/labels'),
  },
  staffs: {
    root: path(ROOTS_DASHBOARD, '/staffs'),
    list: path(ROOTS_DASHBOARD, '/staffs/list'),
    currentUser: path(ROOTS_DASHBOARD, '/staffs/currentUser'),
  },
  settings: {
    root: path(ROOTS_DASHBOARD, '/settings/account'),
    account: path(ROOTS_DASHBOARD, '/settings/account'),
    organisation: path(ROOTS_DASHBOARD, '/settings/organisation'),
    logo: path(ROOTS_DASHBOARD, '/settings/organisation/logo'),
    billing: path(ROOTS_DASHBOARD, '/settings/billing'),
  },
  billing: {
    root: path(ROOTS_DASHBOARD, '/billing/portal'),
    portal: path(ROOTS_DASHBOARD, '/billing/portal'),
  },
  integrations: {
    root: path(ROOTS_DASHBOARD, '/integrations/api'),
    api: path(ROOTS_DASHBOARD, '/integrations/api'),
  },
};
