import { useRef, useState, useEffect } from 'react';
import { AsYouType } from 'libphonenumber-js';
// const
import { COUNTRIES } from '../constants/countries';
// util
import {
  getCallingCodeOfCountry,
  matchContinentsIncludeCountry,
  matchIsArray,
  removeOccurrence,
} from '../utils/telFieldHelpers';

export function getInitialState(params) {
  const { defaultCountry, initialValue, disableFormatting, forceCallingCode } = params;

  const fallbackValue = defaultCountry ? `+${COUNTRIES[defaultCountry]?.[0]}` : '';

  const asYouType = new AsYouType(defaultCountry);
  let inputValue = asYouType.input(initialValue);

  if (forceCallingCode && inputValue === '+' && defaultCountry) {
    inputValue = `+${COUNTRIES[defaultCountry]?.[0]}`;
  }

  const phoneNumberValue = asYouType.getNumberValue();

  if (disableFormatting && phoneNumberValue) {
    inputValue = phoneNumberValue;
  }

  return {
    inputValue: inputValue || fallbackValue,
    isoCode: asYouType.getCountry() || defaultCountry || null,
  };
}

function matchIsIsoCodeAccepted(isoCode, filters) {
  const { excludedCountries, onlyCountries, continents } = filters;

  if (matchIsArray(excludedCountries, true) && excludedCountries.includes(isoCode)) {
    return false;
  }

  if (matchIsArray(onlyCountries) && !onlyCountries.includes(isoCode)) {
    return false;
  }

  if (matchIsArray(continents) && !matchContinentsIncludeCountry(continents, isoCode)) {
    return false;
  }

  return true;
}

const usePhoneDigits = ({
  value,
  onChange,
  defaultCountry,
  onlyCountries,
  excludedCountries,
  continents,
  disableFormatting,
  forceCallingCode,
}) => {
  const previousCountryRef = useRef(defaultCountry || null);
  const asYouTypeRef = useRef(new AsYouType(defaultCountry));
  const inputRef = useRef(null);
  const [previousDefaultCountry, setPreviousDefaultCountry] = useState(defaultCountry);
  const [previousValue, setPreviousValue] = useState(value);
  const [state, setState] = useState(() =>
    getInitialState({
      initialValue: value,
      defaultCountry,
      disableFormatting,
      forceCallingCode,
    })
  );

  const buildOnChangeInfo = (reason) => ({
    countryCallingCode: asYouTypeRef.current.getCallingCode() || null,
    countryCode: asYouTypeRef.current.getCountry() || null,
    nationalNumber: asYouTypeRef.current.getNationalNumber(),
    numberType: asYouTypeRef.current.getNumber()?.getType() ?? null,
    numberValue: asYouTypeRef.current.getNumberValue() || null,
    reason,
  });

  const matchIsIsoCodeValid = (isoCode) =>
    isoCode &&
    matchIsIsoCodeAccepted(isoCode, {
      onlyCountries,
      excludedCountries,
      continents,
    });

  const typeNewValue = (inputValue) => {
    asYouTypeRef.current.reset();

    return asYouTypeRef.current.input(inputValue);
  };

  const makeSureStartWithPlusOrEmpty = (inputValue) =>
    inputValue.startsWith('+') || inputValue === '' ? inputValue : `+${inputValue}`;

  const makeSureStartWithPlusIsoCode = (inputValue, country) => `+${getCallingCodeOfCountry(country)}${inputValue}`;

  const onInputChange = (event) => {
    const inputValue = forceCallingCode
      ? makeSureStartWithPlusIsoCode(event.target.value, state.isoCode)
      : makeSureStartWithPlusOrEmpty(event.target.value);

    // formatted : e.g: +33 6 26 92..
    const formattedValue = typeNewValue(inputValue);
    const newCountryCode = asYouTypeRef.current.getCountry();
    const country = forceCallingCode
      ? // always the same country, can't change
        state.isoCode
      : newCountryCode || previousCountryRef.current;
    // Not formatted : e.g: +336269226..
    const numberValue = asYouTypeRef.current.getNumberValue() || '';

    previousCountryRef.current = country;

    const phoneInfo = buildOnChangeInfo('input');

    // Check if the country is excluded, or not part on onlyCountries, etc..
    if (numberValue && (!country || !matchIsIsoCodeValid(country))) {
      onChange?.(numberValue, {
        ...phoneInfo,
        // we show the input value but without any formatting, or country..
        countryCode: null,
        countryCallingCode: null,
        nationalNumber: null,
      });
      setPreviousValue(numberValue);
      setState({
        isoCode: null,
        inputValue: numberValue,
      });
    } else {
      const valueToSet = disableFormatting ? numberValue : formattedValue;
      onChange?.(valueToSet, phoneInfo);
      setPreviousValue(valueToSet);
      setState({
        isoCode: country,
        inputValue: valueToSet,
      });
    }
  };

  useEffect(() => {
    if (value !== previousValue) {
      setPreviousValue(value);
      const newState = getInitialState({
        initialValue: value,
        defaultCountry,
        forceCallingCode,
        disableFormatting,
      });
      previousCountryRef.current = newState.isoCode;
      setState(newState);
    }
  }, [value, previousValue, defaultCountry, forceCallingCode, disableFormatting]);

  useEffect(() => {
    if (defaultCountry !== previousDefaultCountry) {
      setPreviousDefaultCountry(defaultCountry);
      asYouTypeRef.current = new AsYouType(defaultCountry);
      const { inputValue, isoCode } = getInitialState({
        initialValue: '',
        defaultCountry,
        forceCallingCode,
        disableFormatting,
      });
      setPreviousValue(inputValue);
      asYouTypeRef.current.input(inputValue);
      previousCountryRef.current = asYouTypeRef.current.getCountry() || null;
      onChange?.(inputValue, buildOnChangeInfo('country'));
      setState({
        inputValue,
        isoCode,
      });
    }
  }, [defaultCountry, previousDefaultCountry, onChange, forceCallingCode, disableFormatting]);

  const onCountryChange = (newCountry) => {
    if (newCountry === state.isoCode) {
      return;
    }

    const callingCode = COUNTRIES[newCountry]?.[0];
    const { inputValue, isoCode } = state;
    const inputValueWithoutCallingCode = isoCode
      ? removeOccurrence(inputValue, `+${getCallingCodeOfCountry(isoCode)}`)
      : inputValue;
    // replace the old calling code with the new one, keeping the rest of the number
    let newValue = `+${callingCode}${inputValueWithoutCallingCode}`;

    if (!disableFormatting) {
      newValue = typeNewValue(newValue);
    }

    onChange?.(newValue, {
      ...buildOnChangeInfo('country'),
      // Some country have the same calling code, so we choose what the user has selected
      countryCode: newCountry,
    });
    previousCountryRef.current = newCountry;
    setPreviousValue(newValue);
    setState({
      isoCode: newCountry,
      inputValue: newValue,
    });
  };

  return {
    inputValue: state.inputValue,
    isoCode: state.isoCode,
    onInputChange,
    onCountryChange,
    inputRef,
  };
};

export default usePhoneDigits;
