import { useState } from 'react';
import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Checkbox, FormGroup, FormControlLabel, Button, MenuItem } from '@mui/material';
// components
import Iconify from '../Iconify';
import MenuPopover from '../MenuPopover';

const RHFTableFilterButton = ({
  groupName,
  name,
  options,
  getOptionLabel,
  allButton,
  atLeastOne,
  onCustomChange,
  ...other
}) => {
  const { control } = useFormContext();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const numSelected = field.value.length;
        const itemCount = options.length;

        const onSelected = (option) =>
          !field.value.includes(option)
            ? [...field.value, option]
            : numSelected === 1 && atLeastOne
            ? [...field.value]
            : field.value.filter((value) => value !== option);

        return (
          <>
            <Button
              variant="text"
              endIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}
              sx={{ px: 2 }}
              onClick={handleOpen}
              color="inherit"
            >
              {groupName}
            </Button>

            <MenuPopover
              open={Boolean(open)}
              anchorEl={open}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              disabledArrow
              sx={{ py: 1, px: 0 }}
            >
              <FormGroup>
                {allButton && (
                  <MenuItem sx={{ px: 0 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={itemCount > 0 && numSelected === itemCount}
                          indeterminate={numSelected > 0 && numSelected < itemCount}
                          onChange={(event) => {
                            if (event.target.checked) {
                              field.onChange(options);

                              if (onCustomChange) {
                                onCustomChange(event);
                              }
                              return;
                            }
                            if (!atLeastOne) {
                              if (onCustomChange) {
                                onCustomChange(event);
                              }
                              field.onChange([]);
                            }
                          }}
                        />
                      }
                      sx={{ flex: 1, mr: 0, px: 2 }}
                      label="All"
                      {...other}
                    />
                  </MenuItem>
                )}
                {options.map((option, index) => (
                  <MenuItem key={option} sx={{ px: 0 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={field.value.includes(option)}
                          onChange={(event) => {
                            field.onChange(onSelected(option));

                            if (onCustomChange) {
                              onCustomChange(event);
                            }
                          }}
                        />
                      }
                      sx={{ flex: 1, mr: 0, px: 2 }}
                      label={getOptionLabel?.length ? getOptionLabel[index] : option}
                      {...other}
                    />
                  </MenuItem>
                ))}
              </FormGroup>
            </MenuPopover>
          </>
        );
      }}
    />
  );
};

RHFTableFilterButton.propTypes = {
  groupName: PropTypes.string,
  onCustomChange: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  getOptionLabel: PropTypes.arrayOf(PropTypes.string),
  allButton: PropTypes.bool,
  atLeastOne: PropTypes.bool,
};

export default RHFTableFilterButton;
