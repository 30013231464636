import { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { TextField, InputAdornment, IconButton } from '@mui/material';
// form
import { useFormContext, Controller } from 'react-hook-form';
// components
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

RHFPasswordField.propTypes = {
  name: PropTypes.string,
  onCustomBlur: PropTypes.func,
  onCustomChange: PropTypes.func,
  helperText: PropTypes.string,
};

export default function RHFPasswordField({ name, onCustomBlur, onCustomChange, helperText, ...other }) {
  const { control } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          onBlur={(event) => {
            field.onBlur(event);

            if (onCustomBlur) {
              onCustomBlur(event);
            }
          }}
          onChange={(event) => {
            field.onChange(event);

            if (onCustomChange) {
              onCustomChange(event);
            }
          }}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
          error={!!error}
          helperText={error ? error.message : helperText || ''}
          {...other}
        />
      )}
    />
  );
}
